<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="vipBox">
                <div class="vipItem" :style="getStyle(index)" v-for="(item,index) in vipList" :key="index">
                    <div class="vipLevel" :style="'background-image:url(' + item.img + ')'">
                        <div class="vipTitle">
                            <span :style="'color:'+item.titc" class="vipLevelText" v-text="item.name"></span>
                            <template v-if="item.current">
                                <span :style="'color:'+item.titc" class="isCurrent">Current level</span>
                            </template>
                            <template v-else>

                            </template>
                            <img :src="item.c" />
                        </div>
                        <div class="vipProcess">
                            <span :style="'color:'+item.titc">Recharge<span :style="'color:'+item.proc+';margin-left:.5rem'">0/100</span></span>
                            <div class="vipProcessText">

                            </div>
                        </div>
                        <div class="vipProcess" style="margin-top:2rem;">
                            <span :style="'color:'+item.titc">Bet Amount<span :style="'color:'+item.proc+';margin-left:.5rem'">0/600</span></span>
                            <div class="vipSubProcessText">

                            </div>
                            <div class="vipProcessDes">
                                <span :style="'color:'+item.titc">V0</span>
                                <span :style="'color:'+item.titc">V1</span>
                            </div>
                        </div>
                    </div>
                    <img :src="item.d" class="vipDesign" />
                    <div class="vipPowerArray">
                        <img :src="item.c" />
                        <span class="vipPowerArrayTitle">Member privilege</span>
                        <div class="vipPowerArrayReal">
                            <span>Withdraw fee<span>2.00%</span></span>
                            <span>Daily withdrawals<span>20</span></span>
                            <span>Single withdrawal limit<span>50000</span></span>
                            <span>Daily withdrawal limit<span>10000000</span></span>
                            <span>Bet Bonus<span>0.5%</span></span>
                        </div>
                    </div>
                </div>
                <div class="controlBox">
                    <div class="controlLeftBox" @click="toPrevious">
                    </div>
                    <div class="controlRightBox" @click="toNext">
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

    import Footer from "../components/Footer.vue"

    import Rocket from "../assets/img/viprocket.png";

    import VIP1 from "../assets/img/vip1.png";
    import VIP1C from "../assets/img/vip1c.png";
    import VIP1D from "../assets/img/vip1d.png";
    import VIP1P from "../assets/img/vip1p.png";

    import VIP2 from "../assets/img/vip2.png";
    import VIP2C from "../assets/img/vip2c.png";
    import VIP2D from "../assets/img/vip2d.png";
    import VIP2P from "../assets/img/vip2p.png";

    import VIP3 from "../assets/img/vip3.png";
    import VIP3C from "../assets/img/vip3c.png";
    import VIP3D from "../assets/img/vip3d.png";
    import VIP3P from "../assets/img/vip3p.png";

    export default {
        name:"VIP",
        data(){
            return {
                Rocket,
                previous:-1,
                current:0,
                next:1,
                vipList:[
                    {
                        img:VIP1,
                        name:"V0",
                        current:true,
                        c:VIP1C,
                        d:VIP1D,
                        titc:'#6c3c02',
                        proc:"#ffc278",
                        powc:"#cf9383",
                        powbg:VIP1P,
                    },
                    {
                        img:VIP2,
                        name:"V1",
                        current:false,
                        c:VIP2C,
                        d:VIP2D,
                        titc:'#322e5c',
                        proc:"#e0d4ff",
                        powc:"#b1a5ce",
                        powbg:VIP2P,
                    },
                    {
                        img:VIP3,
                        name:"V2",
                        current:false,
                        c:VIP3C,
                        d:VIP3D,
                        titc:'#6c3c02',
                        proc:"#fcffd4",
                        powc:"#fbe78b",
                        powbg:VIP3P,
                    }
                ],
                isDragging: false
            }
        },
        components:{
            Footer,
        },
        methods:{
            toPrevious(){
                if(this.current == 0){
                    return ;
                }
                this.next = this.current
                this.current = this.previous;
                this.previous = this.previous - 1;
            },
            toNext(){
                if(this.current == this.vipList.length -1){
                    return ;
                }
                this.previous = this.current;
                this.current = this.next;
                this.next = this.current + 1;
            },
            getStyle(index){
                switch(index){
                    case this.current:
                        return 'left:3rem'
                    case this.previous:
                        return 'left:-40rem'
                    case this.next:
                        return 'left:46rem'
                    default:
                        return 'display:none';
                }
            }
        },
        mounted(){
        }
    }

</script>

<style scoped>
    .controlBox>div{
        width:50%;
        height:100%;
    }
    .controlBox{
        width:calc(100% - 6rem);
        height:25rem;
        position:absolute;
        z-index:99;
        display:flex;
    }
    .vipPowerArrayDes>div>span:nth-child(2){
        margin-top:.3rem;
    }
    .vipPowerArrayDes>div{
        margin-bottom:3rem;
        color:#fff;
        font-size:1.4rem;
        display:flex;
        flex-direction: column;
        font-weight:bold;
    }
    .vipPowerArrayReal>span>span{
        color:#fff;
    }
    .vipPowerArrayReal>span{
        color:var(--viptext);
        font-size:1.4rem;
        font-weight:bold;
        margin-bottom:.5rem;
    }
    .vipPowerArrayReal{
        display:flex;
        flex-direction: column;
        margin-top:2rem;
        align-items: center;
    }
    .vipPowerArrayTitle{
        color:var(--blue3);
        font-weight:bold;
        font-size:1.8rem;
        margin-top:2rem;
    }
    .vipPowerArray>img{
        width:10rem;
    }
    .vipPowerArray{
        background:var(--primary);
        box-sizing:border-box;
        padding-top:2rem;
        padding-bottom:3rem;
        border-radius:1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-top:1.5rem;
    }
    .viewPowerDays{
        height:3rem;
        width:3rem;
        background-color:rgba(255,255,255,.3);
        border-radius:.5rem;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        color:#fff;
        font-weight:bold;
        margin-top:1rem;
    }
    .vipPowerLast{
        margin-top:auto;
        font-size:1.4rem;
        font-weight:bold;
        margin-bottom:.5rem;
    }
    .vipPowerItem>svg{
        margin-top:auto;
        height:3rem;
        width:3rem;
    }
    .vipPowerItem>span:nth-child(3){
        font-size:1.8rem;
        font-weight:bold;
        text-align: center;
        margin-top:.7rem;
    }
    .vipPowerItem>img{
        opacity: .3;
        height:3rem;
        margin-top:1rem;
    }
    .vipPowerItem>span:nth-child(1){
        font-size:1.5rem;
        font-weight:bold;
        text-align: center;
    }
    .vipPowerItem{
        width:30%;
        height:17rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        box-sizing:border-box;
        padding:1rem 1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        padding-bottom:.5rem;
    }
    .vipPowerList{
        width:100%;
        display:flex;
        margin-top:2rem;
        justify-content: space-between;
    }
    .vipPowerTitle{
        margin-top:2rem;
        font-size:1.7rem;
        font-weight:bold;
        text-align: center;
    }
    .vipDesign{
        width:100%;
        margin-top:2rem;
    }
    .vipProcessDes{
        width:100%;
        justify-content: space-between;
        display: flex;
        margin-top:.3rem;
        font-size:1.2rem;
        font-weight:bold;
    }
    .vipProcessText{
        background:rgba(0,0,0,0.6);
        height:1.5rem;
        width:55%;
        border-radius:1rem;
        margin-top:.5rem;
    }
    .vipSubProcessText{
        background:rgba(0,0,0,0.6);
        height:1rem;
        width:100%;
        border-radius:1rem;
        margin-top:.5rem;
    }

    .vipProcess>span{
        font-size:1.4rem;
        font-weight:bold;
    }
    .vipProcess{
        display:flex;
        flex-direction: column;
    }
    .isCurrent{
        font-size:1.4rem;
        font-weight:bold;
    }
    .vipLevelText{
        font-size:3.5rem;
        font-weight:bold;
    }
    .vipTitle>img{
        height:10rem;
    }
    .vipTitle{
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .vipLevel{
        height:25rem;
        width:100%;
        background-repeat:no-repeat;
        background-size:100% 100%;
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:2rem 2.5rem;
    }
    .vipItem{
        height:100%;
        width:calc(100% - 6rem);
        position:absolute;
        transition:all .5s;
    }
    .vipBox{
        height:130rem;
        position:relative;
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:3rem;
        background:var(--subblack);
        overflow:hidden;
    }
</style>