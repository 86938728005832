<template>
  <div class="viewBoxGlobal">
    <div class="box">
      <div class="profileBox">
        <div class="profileHeader">
          <div class="profilePicture" @click="showEditAvatar">
            <img class="level1" :src="Level1" />
            <div class="levelTextBox" :style="'background-image:url(' + LevelBox + ')'">
              <span class="levelText">{{$store.state.user?.user?.levelName}}</span>
            </div> 
            <img class="headPic" :src="HeaderPic" />
          </div>
          <span class="profileName">
            {{ $store.state.user?.user?.userName ? $store.state.user?.user?.userName : $store.state.user?.user?.userId }}
            <!-- {{ $store.state.user?.user?.userName ? $store.state.user?.user?.userName : $store.state.user?.user?.userId }}
            {{ $store.state.user?.user?.userName ? $store.state.user?.user?.userName : $store.state.user?.user?.userId }} -->
          </span>
          <span class="profilePho">{{$store.state.user?.user?.userId}}</span>
        </div>
        <div class="profileInfo"> 
          <div class="profileTextPho">
            <img :src="Phone" />
            <span class="profileTextNumber">{{ $store?.state?.user?.phone ? $store.state.user.phone : $t("Unbound") }}</span>
          </div>
          <div class="profileTextEmail">
            <img :src="Email" />
            <span class="profileEmail">{{ $store?.state?.user?.email ? $store.state.user.email : $t("Unbound") }}</span>
          </div>
        </div>
        <svg
          @click="toSettings"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="200"
        >
          <path
            d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
            fill="#8da5ca"
          ></path>
        </svg>
      </div>
      <div class="profileNav">
        <div
          @click="changeActive(index)"
          :class="item.active ? 'profileNavItem active buttonTransition' : 'profileNavItem buttonTransition'"
          v-for="(item, index) in navList"
          :key="index"
        >
          <img :src="item.img" />
          <span v-text="item.name"></span>
        </div>
      </div>
      <template v-if="navList[0].active">
        <div class="profileNotice" @click="toDeposit">
          <div class="swiper-container2">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <span>8% Daily Desposit Bonus</span>
                <span>(0/1)</span>
              </div>
              <div class="swiper-slide">
                <span>8% Daily Desposit Bonus</span>
                <span>(0/1)</span>
              </div>
              <div class="swiper-slide">
                <span>8% Daily Desposit Bonus</span>
                <span>(0/1)</span>
              </div>
            </div>
          </div>
        </div>
        <div class="profileBalance" @click="toDeposit">
          <span>Balance</span>
          <div class="profilePlus">
            <span>P1</span>
            <div class="profilePlusButton">＋</div>
          </div>
        </div>
        <div class="profileProcess">
          <div class="profileProcessLeft">
            <span>Withdrawal</span>
            <div class="profileProcessAccount" @click="toWithdrawal">
              <img :src="Wallet" />
              <span class="walletBalance">P&nbsp;0</span>
              <span class="walletDes">
                Withdrawable<br />
                Amount
              </span>
            </div>
          </div>
          <div class="profileProcessRight">
            <div class="rightFirst">
              <span>0/1</span>
              <svg
                @click="showBet"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
              >
                <path
                  d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m32 704h-64v-64h64v64z m-64-128V256h64v384h-64z"
                  fill="#647183"
                ></path>
              </svg>
            </div>
            <div class="rightSecond">
              <div class="rightSecondProcess"></div>
              <span>0.00%</span>
            </div>
            <div class="rightThird">
              Play to unlock more withdrawable amount
            </div>
            <div class="rightFourth">
              <span class="amountDes">Amount to be unlocked</span>
              <span class="amountValue">P1</span>
            </div>
          </div>
        </div>
        <div class="profileNav" style="margin-top: 1rem">
          <div
            @click="changeActive2(index)"
            :class="item.active ? 'profileNavItem active buttonTransition' : 'profileNavItem buttonTransition'"
            v-for="(item, index) in nav2List"
            :key="index"
          >
            <span v-html="item.name" style="margin:0"></span>
          </div>
        </div>
        <div class="searchBox">
          <DatePicker></DatePicker>
          <div class="searchButton buttonTransition">Search</div>
        </div>
        <template v-if="nav2List[0].active"> <!--充值记录-->
          <TableSimple :data="depositTable.data" :column="depositTable.column"></TableSimple>
          
          <Loading v-show="depositTable.loading"></Loading>
          <div v-if="depositTable.data.length < depositTable.total" style="text-align: center;cursor:pointer">
            <div @click="getDepositList" class="loadingMore">{{ $t("LoadMore") }}</div>
          </div>
        </template>
        <template v-else> <!--提现记录-->
          <TableSimple :data="withdrawTable.data" :column="withdrawTable.column"></TableSimple>
          <Loading v-show="withdrawTable.loading"></Loading>
          <div v-if="withdrawTable.data.length < withdrawTable.total" style="text-align: center;cursor:pointer">
            <div @click="getWithdrawList" class="loadingMore">{{ $t("LoadMore") }}</div>
          </div>
        </template>
      </template>
      <template v-else>
        <div
          @click="toVIP"
          class="rankBox"
          :style="'background-image:url(' + Rank + ')'"
        >
          <div class="rankButton">All Ranks</div>
          <div class="rankData">
            <div class="rankDataItem">
              <div class="rankDataLabel">Recharge</div>
              <div class="rankDataProcess"></div>
              <div class="rankDataValue">0/100</div>
            </div>
            <div class="rankDataItem" style="margin-top: 0.5rem">
              <div class="rankDataLabel">Bet Amount</div>
              <div class="rankDataProcess"></div>
              <div class="rankDataValue">0/600</div>
            </div>
          </div>
        </div>
        <div
          class="levelBox"
          @click="toDetails"
          :style="'background-image:url(' + LevelBg + ')'"
        >
          <div class="currentLevel">
            <div class="currentTitle">CurrentV0</div>
            <div class="currentFields">
              <div class="currentField">
                <span class="currentFieldLabel">Slot</span>
                <span class="currentFieldValue">0.40%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">In-House</span>
                <span class="currentFieldValue">0.50%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">Table</span>
                <span class="currentFieldValue">0.40%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">Live</span>
                <span class="currentFieldValue">0.40%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">Fish</span>
                <span class="currentFieldValue">0.40%</span>
              </div>
            </div>
          </div>
          <div class="transitionBox">
            <Transition :margin="5"></Transition>
          </div>
          <div class="currentLevel">
            <div class="nextTitle">NextV1</div>
            <div class="currentFields">
              <div class="currentField">
                <span class="currentFieldLabel">&nbsp;</span>
                <span class="nextFieldValue">0.40%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">&nbsp;</span>
                <span class="nextFieldValue">0.55%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">&nbsp;</span>
                <span class="nextFieldValue">0.40%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">&nbsp;</span>
                <span class="nextFieldValue">0.40%</span>
              </div>
              <div class="currentField">
                <span class="currentFieldLabel">&nbsp;</span>
                <span class="nextFieldValue">0.40%</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <Footer></Footer>

    <Modal :visible.sync="betVisible" :gradient="true" :padding="false">
      <div class="bonusBox">
        <div class="bonusTitle">
          <span></span>
          <span>Bet Coefficient</span>
          <svg
            @click="betVisible = false"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
          >
            <path
              d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z"
              fill="#5e6f8f"
            ></path>
          </svg>
        </div>
        <div class="bonusText">
          <div class="bonusTextItem">
            <span class="bonusItemTitle">Game</span>
            <span class="bonusItemValue"> Mines </span>
            <span class="bonusItemValue"> swtwo </span>
            <span class="bonusItemValue"> Fruit Slots </span>
            <span class="bonusItemValue"> Smash </span>
            <span class="bonusItemValue"> Christmas Gift </span>
            <span class="bonusItemValue"> Slot Game </span>
            <span class="bonusItemValue"> EVO Casino </span>
            <span class="bonusItemValue"> EVOPLAY </span>
          </div>
          <div class="bonusTextItem">
            <span class="bonusItemTitle">Coefficient</span>
            <span class="bonusItemValue"> 1.00 </span>
            <span class="bonusItemValue"> 1.00 </span>
            <span class="bonusItemValue"> 1.00 </span>
            <span class="bonusItemValue"> 1.00 </span>
            <span class="bonusItemValue"> 1.00 </span>
            <span class="bonusItemValue"> 1.00 </span>
            <span class="bonusItemValue"> 1.00 </span>
            <span class="bonusItemValue"> 1.00 </span>
          </div>
        </div>
      </div>
    </Modal>
    <Modal :visible.sync="editAvatarVisible">
      <div class="avatarBox">
        <div class="bonusTitle" style="margin-top: 2rem">
          <span>Change avatar</span>
        </div>
        <div class="avatarList">
          <div
            class="avatarItem"
            @click="changeAvatar(index)"
            v-for="(item, index) in editAvatarList"
            :key="index"
          >
            <img
              :src="item.avatar"
              :style="item.active ? '' : 'filter: grayscale(100%);'"
            />
            <div class="activeDesign" v-if="item.active">
              <div class="activeRight">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                  <path d="M442.88 1010.176c-147.968-183.296-322.048-322.048-442.88-365.568l286.72-172.032 138.24 268.8S650.24 195.584 1006.08 13.824C998.4 143.872 963.072 256.512 1024 395.264c-156.16 35.328-476.672 424.96-581.12 614.912z" fill="#ffffff"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="entryButton buttonTransition" @click="editAvatarVisible = false">OK</div>
      </div>
    </Modal>
  </div>
</template>

<script>

import Footer from "../components/Footer.vue";
import DatePicker from "../components/DatePicker.vue";
import Empty from "../components/Empty.vue";
import Transition from "../components/Transition.vue";
import Modal from "../components/Modal.vue";
import Loading from "@/components/Loading.vue";
import TableSimple from "@/components/TableSimple.vue";

import Phone from "../assets/img/phone.png";
import Email from "../assets/img/email.png";
import Level1 from "../assets/img/level1.png";
import LevelBox from "../assets/img/levelBox.png";
import Account from "../assets/img/account.png";
import Info from "../assets/img/info.png";
import Wallet from "../assets/img/wallet.png";
import Rank from "../assets/img/rank.png";
import LevelBg from "../assets/img/levelBg.png";

import Swiper, { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Autoplay]);

import {formatDate} from "@/utils/dateUtil.js"
import {orderPage, withdrawalPage} from "@/api/user"

import Head1 from "@/assets/simplewheel/1.png";
import Head2 from "@/assets/simplewheel/2.png";
import Head3 from "@/assets/simplewheel/3.png";
import Head4 from "@/assets/simplewheel/4.png";
import Head5 from "@/assets/simplewheel/5.png";
import Head6 from "@/assets/simplewheel/6.png";
import Head7 from "@/assets/simplewheel/7.png";
import Head8 from "@/assets/simplewheel/8.png";
import Head9 from "@/assets/simplewheel/9.png";
import Head10 from "@/assets/simplewheel/10.png";
import Head11 from "@/assets/simplewheel/11.png";
import Head12 from "@/assets/simplewheel/12.png";

const imageMap = {
  1: require('@/assets/simplewheel/1.png'),
  2: require('@/assets/simplewheel/2.png'),
  3: require('@/assets/simplewheel/3.png'),
  4: require('@/assets/simplewheel/4.png'),
  5: require('@/assets/simplewheel/5.png'),
  6: require('@/assets/simplewheel/6.png'),
  7: require('@/assets/simplewheel/7.png'),
  8: require('@/assets/simplewheel/8.png'),
  9: require('@/assets/simplewheel/9.png'),
  10: require('@/assets/simplewheel/10.png'),
  11: require('@/assets/simplewheel/11.png'),
  12: require('@/assets/simplewheel/12.png'),
  // 添加更多映射
};
// 根据avatar获取图片
const getAvatarImage = (avatar) => imageMap[avatar] || imageMap[1];

export default {
  name: "Mine",
  computed:{
    HeaderPic() {
      return getAvatarImage(this.$store.state.user.avatar);
    }
  },
  data() {
    return {
      formatDate:formatDate,
      Phone, // 手机图标
      Email, // 邮件图标
      Level1, // 等级容器
      LevelBox, // 等级文本容器
      Wallet, // 钱包图标
      Rank, // 排名图标
      LevelBg, // 等级介绍背景
      editAvatarList: [ // 头像选择列表
        {
          avatar: Head1,
          active: true,
        },
        {
          avatar: Head2,
          active: false,
        },
        {
          avatar: Head3,
          active: false,
        },
        {
          avatar: Head4,
          active: false,
        },
        {
          avatar: Head5,
          active: false,
        },
        {
          avatar: Head6,
          active: false,
        },
        {
          avatar: Head7,
          active: false,
        },
        {
          avatar: Head8,
          active: false,
        },
        {
          avatar: Head9,
          active: false,
        },
        {
          avatar: Head10,
          active: false,
        },
        {
          avatar: Head11,
          active: false,
        },
        {
          avatar: Head12,
          active: false,
        },
      ],
      navList: [ // 头部导航选择
        {
          name:this.$t("MyAccount"),
          active: true,
          img: Account,
        },
        {
          name:this.$t("MyInfo"),
          active: false,
          img: Info,
        },
      ],
      nav2List: [ // 提现与充值导航选择
        {
          name: this.$t("DepositHistory"),
          active: true,
        },
        {
          name: this.$t("WithdrawalHistory"),
          active: false,
        },
      ],
      depositTable:{ // 充值表格
        column:[
          {
            name:this.$t("TransactionID"),
            key:"id"
          },
          {
            name:this.$t("Date"),
            key:"date"
          },
          {
            name:this.$t("DepositAmount"),
            key:"depositAmount"
          },
          {
            name:this.$t("Bonus"),
            key:"bonus"
          },
          {
            name:this.$t("Status"),
            key:"status"
          }
        ],
        data:[],
        page:1,
        total:0,
        loading:false,
      },
      withdrawTable:{ // 提现表格
        column:[
        {
            name:this.$t("TransactionID"),
            key:"id"
          },
          {
            name:this.$t("Date"),
            key:"date"
          },
          {
            name:this.$t("Amount"),
            key:"depositAmount"
          },
          {
            name:this.$t("WithdrawFee"),
            key:"bonus"
          },
          {
            name:this.$t("Status"),
            key:"status"
          },
          {
            name:this.$t("Details"),
            key:"details"
          }
        ],
        data:[],
        page:1,
        total:0,
        loading:false,
      },
      swiper: null, // 从上向下的swiper

      betVisible: false, // 弹窗显示
      editAvatarVisible: false, // 修改头像弹窗显示
    };
  },
  components: {
    Footer,
    Empty,
    Transition,
    Modal,
    DatePicker,
    Loading,
    TableSimple
  },
  methods: {
    changeAvatar(index) { // 改变头像
      this.editAvatarList.forEach((item, index2) => {
        if (index === index2) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    showEditAvatar() { // 显示改变头像弹窗
      this.editAvatarVisible = true;
    },
    toVIP() { // 跳转到vip页
      this.$router.push("/VIP");
    },
    showBet() { // 显示弹窗
      this.betVisible = true;
    },
    toWithdrawal() { // 跳转到提现页
      this.$router.push({
        path: "/deposit",
      });
    },
    toDeposit() { // 跳转到充值页
      this.$router.push({
        path: "/deposit",
        query: {
          mode: "deposit",
        },
      });
    },
    toSettings() { // 跳转到设置页
      this.$router.push("/setting");
    },
    toDetails() { // 挑战到betbonus页
      this.$router.push("/betbonus");
    },
    changeActive(index) { // 改变头部nav的时候
      if (index == 0) {
        this.initSwiper();
      } else {
        this.swiper.destroy();
      }
      this.navList.forEach((item, i) => {
        if (i === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    changeActive2(index) { // 改变充值列表或是提现列表
      this.nav2List.forEach((item, i) => {
        if (i === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    initSwiper() { // 初始化swiper
      this.$nextTick(() => {
        setTimeout(() => {
          this.swiper = new Swiper(".swiper-container2", {
            direction: "vertical", // 设置为垂直方向
            loop: true, // 是否循环播放
            autoplay: {
              reverseDirection: true,
              disableOnInteraction: false,
              delay: 5000, // 设置自动轮播间隔时间，单位为毫秒
            },
            speed: 1000,
          });
        }, 1);
      });
    },
    getDepositList(){ // 加载充值列表
      if(!this.depositTable.loading){
        this.depositTable.loading = true;
        let data = {
          currentPage:this.depositTable.page
        }
        orderPage(data).then(res => {
          this.depositTable.data = [...this.depositTable.data,...res.data.records.map(item => {
            return {
              id:item.outTradeNo,
              date:formatDate(item.createTime), 
              bonus:item.feeAmount,
              depositAmount:item.rechargeAmount,
              status:item.status == 1 ? this.$t("CompletePayment") : this.$t("TimeoutCancelled"),
              statusc:item.status == 1 ? 'green' : 'red'
            }
          })];
          this.depositTable.total = res.data.total;
          this.depositTable.loading = false;
        }).catch(err => {
          this.depositTable.loading = false;
        })
      }
    },
    getWithdrawList(){ // 加载提现列表
      if(!this.withdrawTable.loading){
        this.withdrawTable.loading = true;
        let data = {
          currentPage:this.withdrawTable.page
        }
        withdrawalPage(data).then(res => {
          this.withdrawTable.data = [...this.withdrawTable.data,...res.data.records];
          this.withdrawTable.total = res.data.total;
          this.withdrawTable.loading = false;
        }).catch(err => {
          this.withdrawTable.loading = false;
        })
      }
    }
  },
  mounted() {
    this.initSwiper();
    this.getDepositList();
    this.getWithdrawList();
  },
};
</script>

<style scoped>

.levelTextBox{
  position:absolute;
  bottom:-0.5rem;
  right:-0.5rem;
  width: 4rem;
  height: 3.8rem;
  background-repeat:no-repeat;
  background-size:100% 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
}

.activeRight > svg {
  width:1.5rem;
  height:1.5rem;
}

.activeRight {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  background: var(--green4);
  border-radius: 50%;
  bottom: 0rem;
  right: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activeDesign {
  position: absolute;
  border: 2px solid var(--green4);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-sizing:border-box;
}

.entryButton {
  margin-top: 3rem;
  width: 100%;
  height: 5rem;
  background: var(--green4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.8rem;
  border-radius: 1rem;
}

.avatarItem img {
  height: 100%;
  width: 100%;
}
.avatarItem:nth-child(4n) {
  margin-right: 0 !important;
}
.avatarItem {
  height: 8rem;
  width: 8rem;
  margin-right: 1.3rem;
  margin-bottom: 1.5rem;
  position: relative;
}
.avatarList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.nicknameInput > input::placeholder {
  color: var(--disabled9);
}
.nicknameInput > input {
  color: #fff;
  outline: none;
  border: none;
  background: none;
  margin-left: 1rem;
  font-weight: bold;
}

.nicknameInput > svg {
  width: 3rem;
  height: 3rem;
}

.nicknameInput {
  border: 1px solid var(--disabled10);
  width: 100%;
  height: 4.5rem;
  margin-top: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 1rem;

  display: flex;
  align-items: center;
}

.avatarBox {
  display: flex;
  flex-direction: column;
}

.bonusItemValue {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 1rem;
}
.bonusItemTitle {
  color: var(--phonetext);
  font-weight: bold;
  font-size: 1.3rem;
  white-space: nowrap;
  margin-bottom: 2rem;
}
.bonusTextItem {
  background: linear-gradient(to bottom, var(--sub7black), var(--sub8black));
  width: 48%;
  display: flex;
  box-sizing: border-box;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4rem;
}
.bonusText {
  margin: 0 auto;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bonusTitle > svg {
  width: 2rem;
  height: 2rem;
}
.bonusTitle > span {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.bonusTitle {
  display: flex;
  justify-content: space-between;
}
.bonusBox {
  box-sizing: border-box;
  padding: 3rem 2rem;
}

.transitionBox {
  width: 12rem;
}
.nextFieldValue {
  color: var(--disabled21);
  font-size: 1.5rem;
}
.currentFieldValue {
  color: var(--green5);
  font-size: 1.5rem;
}
.currentFieldLabel {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}
.currentField {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.currentFields {
  display: flex;
  flex-direction: column;
}
.nextTitle {
  background-color: var(--orange);
  color: #fff;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  border-radius: 0.8rem;
  font-size: 1.3rem;
  font-weight: bold;
}
.currentTitle {
  background-color: var(--green6);
  color: #fff;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  border-radius: 0.8rem;
  font-size: 1.3rem;
  font-weight: bold;
}
.currentLevel {
  display: flex;
  flex-direction: column;
}
.levelBox {
  display: flex;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 1.5rem 4rem;
  padding-left: 15rem;
  border-radius: 1rem;
}
.rankDataValue {
  color: var(--green7);
  font-size: 1.5rem;
  margin-top: 0.5rem;
  font-weight: bold;
}
.rankDataProcess {
  position: relative;
  background: var(--sub11black);
  width: 100%;
  border-radius: 1rem;
  height: 1rem;
  margin-top: 1rem;
}
.rankDataLabel {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  box-sizing: border-box;
  padding-left: 4rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
}
.rankDataItem {
  display: flex;
  flex-direction: column;
}
.rankData {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-left: 1.5rem;
}
.rankButton {
  color: var(--blue3);
  font-size: 1.5rem;
  width: 11rem;
  text-align: center;
  box-sizing: border-box;
  padding-top: 11rem;
  font-weight: bold;
}
.rankBox {
  height: 20rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 2rem;
  display: flex;
  box-sizing: border-box;
  padding: 3rem 2rem;
}
.searchButton {
  background: var(--blue);
  color: #fff;
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 0 2em;
  height: 3.5rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.searchBox {
  margin-top: 1rem;
  display: flex;
}
.myRange {
  width: 26rem !important;
  height: 3.5rem !important;
}
.amountValue {
  color: var(--disabled11);
  font-size: 1.5rem;
}
.amountDes {
  color: var(--disabled2);
  font-size: 1.5rem;
}
.rightFourth {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  align-items: center;
  margin-top: auto;
}
.rightThird {
  color: #fff;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  width: 70%;
}
.rightSecondProcess {
  width: 100%;
  background: var(--sub12black);
  border-radius: 2rem;
  height: 100%;
}
.rightSecond > span {
  color: var(--viptext);
  font-size: 1.3rem;
}
.rightSecond {
  height: 1rem;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.rightFirst > span {
  color: var(--green7);
  font-size: 1.5rem;
}
.rightFirst > svg {
  height: 2rem;
  width: 2rem;
}
.rightFirst {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.profileProcessRight {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-left: 1.5rem;
}
.walletDes {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
}
.walletBalance {
  color: var(--green7);
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 1rem;
}
.profileProcessAccount img {
  width: 4rem;
}
.profileProcessAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  width: 15rem;
  margin-top: 1rem;
}
.profileProcessLeft > span {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
}
.profileProcessLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileProcess span {
  font-weight:bold;
}
.profileProcess {
  display: flex;
  height: fit-content;
  width: 100%;
  margin-top: 1rem;
  border-radius: 1rem;
  background: radial-gradient(circle at 25% 10%, var(--blue5), var(--sub13black));
  padding: 2rem 1rem 1.5rem 2rem;
  box-sizing: border-box;
}
.profilePlusButton {
  background: linear-gradient(to top right, var(--purplebg5), var(--rosered));
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  box-sizing: border-box;
  padding-bottom: 0.5rem;
}
.profilePlus > span {
  color: #fff;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  font-weight:bold;
}
.profilePlus {
  display: flex;
  color: #fff;
  align-items: center;
}
.profileBalance > span {
  color: #fff;
  font-size: 1.5rem;
  font-weight:bold;
}
.profileBalance {
  display: flex;
  margin-top: 2rem;
  border: 1px solid var(--disabled12);
  border-radius: 1rem;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  box-sizing: border-box;
  padding: 0 1.5rem;
  padding-right: 0.5rem;
}
.swiper-container2 {
  width: 100%;
  height: 100%; /* 设置轮播高度 */
  margin-left: 1rem;
  overflow: hidden;
}
.swiper-slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 2rem;
  height: 100% !important; /* 设置轮播项高度 */
  font-weight:bold;
}
.swiper-slide > span:nth-child(1) {
  color: #fff !important;
  font-size: 1.5rem;
}
.swiper-slide > span:nth-child(2) {
  color: var(--green8) !important;
  font-size: 1.5rem;
}

.profileNotice {
  height: 3rem;
  width: 100%;
  margin-top: 2rem;
  background: linear-gradient(to bottom, var(--subblack) 0%, var(--blue6) 50%, var(--subblack) 100%);
}
.profileNavItem.active span {
  color: #fff !important;
}
.profileNavItem.active {
  background: var(--purplebg6);
}
.profileNavItem img {
  height: 3rem;
}
.profileNavItem span {
  display:inline;
  width:fit-content;
  color: var(--disabled2);
  font-size: 1.3rem;
  margin-left: 1rem;
  word-break: break-all;
  font-weight:bold;
}
.profileNavItem {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  box-sizing:border-box;
  padding:0 2rem;
  text-align: center;
}
.profileNav {
  width: 100%;
  display: flex;
  background: var(--primary);
  height: 5rem;
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
}
.levelText {
  color: var(--orange2);
  font-size: 1.1rem;
  font-weight: bold;
  transform:rotate(-90deg);
}
.level1 {
  width: 100%;
  position: absolute;
  left: 0rem;
  top: 0rem;
  transform: scale(1.03);
}
.profileBox svg {
  height: 2rem;
  width: 2rem;
  margin-left: 6rem;
}
.profileInfo {
  height: fit-content;
  margin-left: 5rem;
}
.profileEmail {
  color: var(--red3);
  font-size: 1.5rem;
  margin-left: 1rem;
  font-weight:bold;
}
.profileTextEmail {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.profileTextEmail img {
  width: 2rem;
}
.profileTextPho {
  display: flex;
  align-items: center;
}
.profileTextPho img {
  width: 2rem;
}
.profileTextNumber {
  color: var(--link7);
  font-size: 1.5rem;
  margin-left: 1rem;
  font-weight:bold;
}
.profilePho {
  color: var(--disabled2);
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 0.5rem;
}
.profileName {
  color: var(--viptext);
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
  width:fit-content;
  word-break: break-all;
  text-align: center;
  width:8rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 指定行数 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.headPic {
  width: 100%;
  height: 100%;
}
.profilePicture {
  height: 8rem;
  width: 8rem;
  position: relative;
}
.profileHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profileBox {
  height: fit-content;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 5rem 4rem;
}
.box {
  background: var(--subblack);
  box-sizing: border-box;
  padding-top: 8rem;
  padding-bottom: 8rem;
  box-sizing: border-box;
  padding: 1rem 1rem;
}
.loadingMore {
  display: inline-block;
  color: var(--disabled13);
  background: var(--sub14black);
  border: 0.1rem solid var(--sub15black);
  border-radius: 1rem;
  margin: 2rem auto;
  padding: 1rem 2rem;
  font-size: 1.5rem;
}
</style>
