<template>
    <div class="childBox">
        <div class="selectBox">
            <el-select v-model="value" class="mySelect">
                <el-option value="All">All</el-option>
                <el-option value="Commission withdrawal">Commission withdrawal</el-option>
                <el-option value="Commission transfer">Commission transfer</el-option>
                <el-option value="Betting Commission">Betting Commission</el-option>
                <el-option value="Invitation Conquest">Invitation Conquest</el-option>
                <el-option value="Invitation bonus">Invitation bonus</el-option>
            </el-select>
        </div>
        <div class="table">
            <div class="tableHeader">
                <div class="tableField" style="width:33%">
                    Time
                </div>
                <div class="tableField" style="width:33%">
                    Type
                </div>
                <div class="tableField" style="width:33%">
                    Bonus
                </div>
            </div>
            <div class="tableBody">
                <template v-if="table.length == 0">
                    <div style="height:25rem;">
                        <Empty></Empty>
                    </div>
                </template>
                <template v-else>

                </template>
            </div>
        </div>
    </div>
</template>

<script>

    import Search from "../assets/img/search.png"
    import Empty from "../components/Empty.vue"

    export default {
        name:"ReferMember",
        data(){
            return {
                Search,
                value:"All",
                table:[]
            }
        },
        components:{
            Empty
        },
        methods:{

        }
    }
</script>

<style scoped>
    .tableBody{
        min-height:25rem;
        height:fit-content;
    }
    .tableField{
        color:var(--disabled6);
        font-size:1.4rem;
        font-weight:bold;
        text-align: center;
    }
    .tableHeader{
        display:flex;
        height:5rem;
        margin-top:1rem;
        align-items: center;
    }
    .table{
        height:fit-content;
        display:flex;
        flex-direction: column;
    }
    .mySelect{
        width:100%;
        margin-top:1rem;
    }
    .inputBox input{
        height:100%;
        width:20rem;
        color:#fff;
        background:none;
        outline:none;
        border:none;
        font-size:1.7rem;
        font-weight:bold;
    }
    .inputBox img{
        height:2.5rem;
    }
    .inputBox input::placeholder{
        color:var(--disabled7);
        font-weight:bold;
    }
    .inputBox{
        height:5rem;
        width:100%;
        background:var(--primary);
        margin-top:2rem;
        border-radius:1rem;
        box-sizing:border-box;
        padding:0 2rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .childBox{
        display:flex;
        flex-direction: column;
    }
</style>