<template>
    <div class="childBox" style="color:#fff">
        <div class="searchBox">
            <DatePicker :startDate.sync="start" :endDate.sync="end"></DatePicker>
            <div class="searchButton buttonTransition">
                Search
            </div>
        </div>
        <div class="headerBox">
            <div @click="changeAction(index)" :class="item.active ? 'headerItem buttonTransition active' : 'headerItem buttonTransition'" v-for="(item,index) in headerList" :key="index">
                <span v-text="item.name"></span>
            </div>
        </div>
        <div class="columnList">
            <div class="columnItem">
                <span class="columnLabel">Total commission</span>
                <span class="columnValue">0</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">Direct top-up members</span>
                <span class="columnValue">0</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">Number of Team members</span>
                <span class="columnValue">0</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">Number of direct members</span>
                <span class="columnValue">0</span>
            </div>

            <div class="columnItem">
                <span class="columnLabel">Commission Withdrawal</span>
                <span class="columnValue">0</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">Commission Transfer</span>
                <span class="columnValue">0</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">Betting Commission</span>
                <span class="columnValue">0</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">Invitation Conquest</span>
                <span class="columnValue">0</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">Invitation bonus</span>
                <span class="columnValue">0</span>
            </div>
        </div>
        <div class="description">The data is the value that increases during the time interval</div>
    </div>
</template>

<script>

    import DatePicker from "../components/DatePicker.vue";

    export default {
        name:"ReferRef",
        data(){
            return {
                headerList:[
                    {
                        name:"All",
                        active:true,
                    },
                    {
                        name:"Today",
                        active:false,
                    },
                    {
                        name:"This week",
                        active:false,
                    },
                    {
                        name:"Last week",
                        active:false,
                    },
                    {
                        name:"Last Month",
                        active:false,
                    },
                ],
                start:"0000-00-00",
                end:"0000-00-00"
            }
        },
        mounted(){
        },
        components:{
            DatePicker
        },
        methods:{
            changeAction(index){
                this.headerList.forEach((item, i) => {
                    if (i === index) {
                        item.active = true;
                    } else {
                        item.active = false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .searchButton{
        background:var(--blue);
        color:#fff;
        border-radius:1rem;
        box-sizing:border-box;
        padding:0 2em;
        height:3.5rem;
        font-size:1.3rem;
        display:flex;
        align-items: center;
        margin-left:1rem;
    }
    .searchBox{
        margin-top:2rem;
        display:flex;
    }
    .myRange{
        width:25rem !important;
        height:3.5rem !important;
    }

    .description{
        color:var(--disabled2);
        text-align: center;
        font-size:1.3rem;
        margin-top:1rem;
    }
    .columnLabel{
        color:var(--label);
        font-size:1.5rem;
    }
    .columnValue{
        color:#fff;
        font-size:1.5rem;
    }
    .columnItem{
        background:var(--primary);
        display:flex;
        justify-content: space-between;
        align-items: center;
        box-sizing:border-box;
        padding:1.5rem 3rem;
        border-radius:1rem;
        margin-top:1rem;
    }
    .columnList{
        display:flex;
        flex-direction: column;
    }

    .headerItem.active{
        background:var(--purplebg4);
    }
    .headerItem.active span{
        color:#fff !important;
    }
    .headerItem span{
        color:var(--disabled2);
        font-size:1.3rem;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:bold;
    }
    .headerItem{
        height:4rem;
        width:8rem;
        background:var(--sub4black);
        border-radius:1rem;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .headerBox{
        display:flex;
        margin-top:2rem;
        justify-content: space-between;
    }
    .childBox{
        height:auto;
        overflow:hidden;
        width:100%;
    }
</style>