<template>
    <div class="childBox" style="color:#fff">
        <div class="noticeBox" :style="'background-image:url(' + Bg + ')'">
            <span class="noticeTitle">Invite your friends to join and you will receive great rewards</span>
            <span class="noticeSubTitle">Your referral link</span>
            <div class="linkBox">
                <span v-text="referralLink"></span>
                <div class="copyButton" @click="copyText" :data-clipboard-text="referralLink" id="copyButton">
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M768 682.666667V170.666667a85.333333 85.333333 0 0 0-85.333333-85.333334H170.666667a85.333333 85.333333 0 0 0-85.333334 85.333334v512a85.333333 85.333333 0 0 0 85.333334 85.333333h512a85.333333 85.333333 0 0 0 85.333333-85.333333zM170.666667 170.666667h512v512H170.666667z m682.666666 85.333333v512a85.333333 85.333333 0 0 1-85.333333 85.333333H256a85.333333 85.333333 0 0 0 85.333333 85.333334h426.666667a170.666667 170.666667 0 0 0 170.666667-170.666667V341.333333a85.333333 85.333333 0 0 0-85.333334-85.333333z" fill="var(--white2)">
                        </path>
                    </svg>
                </div>
            </div>
            <span class="noticeSubTitle">Share via:</span>
            <div class="shareList">
                <div class="shareItem" @click="toOther">
                    <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M937.4 423.9c-84 0-165.7-27.3-232.9-77.8v352.3c0 179.9-138.6 325.6-309.6 325.6S85.3 878.3 85.3 698.4c0-179.9 138.6-325.6 309.6-325.6 17.1 0 33.7 1.5 49.9 4.3v186.6c-15.5-6.1-32-9.2-48.6-9.2-76.3 0-138.2 65-138.2 145.3 0 80.2 61.9 145.3 138.2 145.3 76.2 0 138.1-65.1 138.1-145.3V0H707c0 134.5 103.7 243.5 231.6 243.5v180.3l-1.2 0.1"></path></svg>
                </div>
                <div class="shareItem" @click="toOther">
                    <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M937.4 423.9c-84 0-165.7-27.3-232.9-77.8v352.3c0 179.9-138.6 325.6-309.6 325.6S85.3 878.3 85.3 698.4c0-179.9 138.6-325.6 309.6-325.6 17.1 0 33.7 1.5 49.9 4.3v186.6c-15.5-6.1-32-9.2-48.6-9.2-76.3 0-138.2 65-138.2 145.3 0 80.2 61.9 145.3 138.2 145.3 76.2 0 138.1-65.1 138.1-145.3V0H707c0 134.5 103.7 243.5 231.6 243.5v180.3l-1.2 0.1"></path></svg>
                </div>
                <div class="shareItem" @click="toOther">
                    <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M937.4 423.9c-84 0-165.7-27.3-232.9-77.8v352.3c0 179.9-138.6 325.6-309.6 325.6S85.3 878.3 85.3 698.4c0-179.9 138.6-325.6 309.6-325.6 17.1 0 33.7 1.5 49.9 4.3v186.6c-15.5-6.1-32-9.2-48.6-9.2-76.3 0-138.2 65-138.2 145.3 0 80.2 61.9 145.3 138.2 145.3 76.2 0 138.1-65.1 138.1-145.3V0H707c0 134.5 103.7 243.5 231.6 243.5v180.3l-1.2 0.1"></path></svg>
                </div>
            </div>
        </div>
        <div class="optionBox">
            <div class="optionList">
                <div class="optionLabel">
                    <span class="optionLabelText">Bonus</span>
                    <span class="optionLabelValue">0</span>
                </div>
                <div class="optionButtonList">
                    <div class="withdrawButton buttonTransition" @click="toWithdraw">
                        Withdraw
                    </div>
                    <div class="transferButton buttonTransition" @click="showModal">
                        Transfer
                    </div>
                </div>
            </div>
            <div class="contentBox">
                Commission settlement will take a while, please be patient
            </div>
        </div>
        <div class="seeMore" @click="toBonus">See all bonuses</div>
        <div class="ruleBox">
            <span class="ruleTitle">Commission Model and Rules</span>
            <div class="ruleItem" v-for="item in 3" :key="item">
                <div class="ruleItemTitle">
                    <span>1.Referral Gift Box</span>
                    <span @click="toHelp">Learn More</span>
                </div>
                <span class="ruleText">
                    Mag-sign up at maglaro ng 5 rounds na laro sapamamagitan ng iyong referral link upang maging kwalipikadong miyembro.&amp;nbsp;
                </span>
            </div>
        </div>
        <div class="rewardsBox">
            <span class="ruleTitle">Invitational Achievement Award</span>
            <div class="rewardsText">
                <svg @click="showBonus" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m32 704h-64v-64h64v64z m11.2-203.2l-5.6 4.8c-3.2 2.4-5.6 8-5.6 12.8v58.4h-64v-58.4c0-24.8 11.2-48 29.6-63.2l5.6-4.8c56-44.8 83.2-68 83.2-108C598.4 358.4 560 320 512 320c-49.6 0-86.4 36.8-86.4 86.4h-64C361.6 322.4 428 256 512 256c83.2 0 150.4 67.2 150.4 150.4 0 72.8-49.6 112.8-107.2 158.4z" fill="var(--disabled2)"></path></svg>
                <div class="rewardsProcess">
                </div>
                <div class="rewardsItems">
                    <span class="rewardsPrice">P25.00</span>
                    <img :src="Coin" />
                    <div class="rewardsGroup">
                        <img :src="Group" />
                        <span>0/5</span>
                    </div>
                </div>
                <div class="rewardsItems" style="left:50%">
                    <span class="rewardsPrice">P25.00</span>
                    <img :src="Coin" />
                    <div class="rewardsGroup">
                        <img :src="Group" />
                        <span>0/5</span>
                    </div>
                </div>
                <div class="rewardsItems" style="left:70%">
                    <span class="rewardsPrice">P25.00</span>
                    <img :src="Coin" />
                    <div class="rewardsGroup">
                        <img :src="Group" />
                        <span>0/5</span>
                    </div>
                </div>
                <div class="rewardsItems" style="left:90%">
                    <span class="rewardsPrice">P25.00</span>
                    <img :src="Coin" />
                    <div class="rewardsGroup">
                        <img :src="Group" />
                        <span>0/5</span>
                    </div>
                </div>
            </div>
            <span class="rewardsDes">
                Become your referral：Register successful and play 5 round games
            </span>
        </div>
        <div class="description">Want to earn P1000000+ a month at home?Refer people continuously.brilliant future and massive income are waiting for you</div>
        <div class="rewardsBox">
            <span class="ruleTitle">Affiliate Program</span>
            <span class="affiliateDes">Income Model</span>
            <div class="levelBox">
                <img :src="Level" />
                <span class="You">You</span>
                <span class="Wager">Wager Rebates</span>
                <span class="Refer">Refer Members</span>
                <span class="level1">Level 1</span>
                <span class="level2">Level 2</span>
                <span class="level3">Level 3</span>
            </div>
        </div>
        <div class="description">Exclusive Affiliate Program. Making Money Easier</div>
        <div class="levelDes">
            <span>Sa multi-level marketing model, ang bawat isa sa iyong mas mababang antas na miyembro ay maaaring gumawang komisyon para sa iyo;</span>
            <span style="margin-top:2rem;">
                Level 1 = level 1 na taya ng miyembro * 0.5%<br/>
                Level 2 = level 2 na taya ng miyembro * 0.5% * 30%<br/>
                Level 3 = level 3 na taya ng miyembro * 0.5% * 30% * 30%<br/>
                Level N = level N na taya ng miyembro * 0.5% * 30%*^(N-1)
            </span>
        </div>
        <Modal :visible="transferVisible" :gradient="true" :padding="false">
            <div class="transferBox">
                <span>The chips transferred to the account can be played directly. Are you sure about the transfer?</span>
                <div class="transferOption">
                    <div class="closeButton" @click="transferVisible = false">
                        Cancel
                    </div>
                    <div class="queryButton" @click="transferVisible = false">
                        OK
                    </div>
                </div>
            </div>
        </Modal>
        <Modal :visible="bonusVisible" :gradient="true" :padding="false">
            <div class="bonusBox">
                <div class="bonusTitle">
                    <span></span>
                    <span>Invitation bonus</span>
                    <svg @click="bonusVisible = false" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path></svg>
                </div>
                <div class="bonusText">
                    <div class="bonusTextItem">
                        <span class="bonusItemTitle">Number of invites</span>
                        <span class="bonusItemValue">
                            5
                        </span>
                        <span class="bonusItemValue">
                            15
                        </span>
                        <span class="bonusItemValue">
                            30
                        </span>
                        <span class="bonusItemValue">
                            80
                        </span>
                        <span class="bonusItemValue">
                            200
                        </span>
                    </div>
                    <div class="bonusTextItem">
                        <span class="bonusItemTitle">Rewards</span>
                        <span class="bonusItemValue">
                            5
                        </span>
                        <span class="bonusItemValue">
                            15
                        </span>
                        <span class="bonusItemValue">
                            30
                        </span>
                        <span class="bonusItemValue">
                            80
                        </span>
                        <span class="bonusItemValue">
                            200
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>

    import Bg from "../assets/img/noticeBg.png"
    import Coin from "../assets/img/coin.png"
    import Group from "../assets/img/group.png"
    import Level from "../assets/img/level.png"
    import Modal from "../components/Modal.vue"

    import Clipboard from 'clipboard';

    export default {
        name:"ReferCom",
        data(){
            return {
                Bg,
                Coin,
                Group,
                Level,
                transferVisible:false,
                bonusVisible:false,
            }
        },
        mounted(){
        },
        computed:{
            referralLink(){
                return `${window.location.origin}/#/?agentid=${this.$store.state.user.user.userId}`
            }
        },
        components:{
            Modal
        },
        methods:{
            toOther(){
                this.$router.push("/other")
            },
            copyText(){
                this.$message.info(this.$t("CopySuccess"));
                new Clipboard('#copyButton')
            },
            showBonus(){
                this.bonusVisible = true;
            },
            toHelp(){
                this.$router.push("/help")
            },
            toWithdraw(){
                this.$router.push("/deposit")
            },
            toBonus(){
                this.$router.push("/refer/bonus")
            },
            showModal(){
                this.transferVisible = true;
            }
        }
    }
</script>

<style scoped>
    .level3{
        color:#fff;
        position:absolute;
        left:46%;
        font-size:1.2rem;
        top:30.2rem;
    }
    .level2{
        color:#fff;
        position:absolute;
        left:46%;
        font-size:1.2rem;
        top:23.5rem;
    }
    .level1{
        color:#fff;
        position:absolute;
        left:46%;
        font-size:1.2rem;
        top:17.2rem;
    }

    .bonusItemValue{
        font-size:1.5rem;
        color:#fff;
        font-weight:bold;
        margin-bottom:1rem;
    }
    .bonusItemTitle{
        color:var(--phonetext);
        font-weight:bold;
        font-size:1.3rem;
        white-space: nowrap;
        margin-bottom:2rem;
    }
    .bonusTextItem{
        background:linear-gradient(to bottom,var(--sub7black),var(--sub8black));
        width:48%;
        display:flex;
        box-sizing:border-box;
        padding:1.5rem 2rem;
        border-radius:1rem;
        align-items: center;
        flex-direction: column;
        margin-bottom:4rem;
    }
    .bonusText{
        margin:0 auto;
        margin-top:3rem;
        display:flex;
        justify-content: space-between;
        width:100%;
    }
    .bonusTitle>svg{
        width:2rem;
        height:2rem;
    }
    .bonusTitle>span{
        color:#fff;
        font-size:2rem;
        font-weight:bold;
    }
    .bonusTitle{
        display:flex;
        justify-content: space-between;
    }
    .bonusBox{
        box-sizing: border-box;
        padding:3rem 2rem;
    }
    .queryButton{
        width:12rem;
        height:4rem;
        background-color:var(--green4);
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.6rem;
        font-weight:bold;
        border-radius:1.5rem;
    }
    .closeButton{
        width:12rem;
        height:4rem;
        background-color:var(--red2);
        color:#fff;
        display:flex;
        align-items: center;
        margin-right:3rem;
        justify-content: center;
        font-size:1.6rem;
        font-weight:bold;
        border-radius:1.5rem;
    }
    .transferOption{
        display:flex;
        justify-content: center;
        margin-top:2rem;
    }
    .transferBox>span{
        color:var(--link4);
        font-size:1.7rem;
        font-weight:bold;
        word-break: break-all
    }
    .transferBox{
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:2rem 2rem;
    }
    .levelDes>span{
        font-size:1.5rem;
        color:#fff;
        text-align: center;
        line-height:2rem;
    }
    .levelDes{
        background:var(--primary);
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding:2rem 1.5rem;
        padding-bottom:10rem;
        margin-top:1.5rem;
    }
    .Refer{
        color:#fff;
        position:absolute;
        left:58%;
        font-size:1.5rem;
        top:12rem;
    }
    .Wager{
        color:#fff;
        position:absolute;
        left:18%;
        font-size:1.5rem;
        top:12rem;
    }
    .You{
        color:#fff;
        position:absolute;
        left:48%;
        font-size:1.5rem;
        top:9rem;
    }
    .levelBox>img{
        width:100%;
        margin-top:2rem;
    }
    .levelBox{
        width:100%;
        height:fit-content;
        position:relative;
    }
    .affiliateDes{
        margin-top:1.5rem;
        font-size:1.5rem;
        color:var(--link5);
        font-weight:bold;
    }
    .rewardsGroup>img{
        height:1rem;
    }
    .rewardsGroup>span{
        color:var(--link4);
        font-size:1.3rem;
        font-weight:bold;
        margin-left:.5rem;
    }
    .rewardsGroup{
        margin-top:.5rem;
    }
    .rewardsPrice{
        color:#fff;
        font-size:1.3rem;
        margin-bottom:.5rem;
    }
    .rewardsItems>img{
        width:4rem;
    }
    .rewardsItems{
        display:flex;
        flex-direction: column;
        align-items: center;
        position:absolute;
        left:30%;
    }
    .rewardsProcess{
        background:var(--popover);
        width:100%;
        height:1.5rem;
        flex:1;
        border-radius:2rem;
    }
    .rewardsText>svg{
        height:2.5rem;
        width:2.5rem;
        margin-right:2rem;
    }
    .rewardsText{
        display:flex;
        align-items: center;
        justify-content: center;
        width:100%;
        margin-bottom:2rem;
        position:relative;
        height:10rem;
    }
    .rewardsDes{
        color:var(--phonetext);
        font-size:1.3rem;
        font-weight:bold;
        text-align: center;
    }
    .rewardsBox{
        background:var(--primary);
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding:2rem 1.5rem;
        margin-top:1.5rem;
    }
    .ruleText{
        margin-top:.5rem;
        color:var(--link3);
        font-size:1.3rem;
        font-weight:bold;
    }
    .ruleItemTitle{
        display:flex;
        justify-content: space-between;
    }
    .ruleItemTitle>span:nth-child(1){
        color:#fff;
        font-weight:bold;
        font-size:1.3rem;
    }
    .ruleItemTitle>span:nth-child(2){
        color:#3084fc;
        font-weight:bold;
        font-size:1.3rem;
        text-decoration: underline;
    }
    .ruleItem{
        width:100%;
        display:flex;
        flex-direction: column;
        margin-top:1rem;
    }
    .ruleTitle{
        color:var(--blue4);
        font-size:1.5rem;
        margin-bottom:1rem;
        font-weight:bold;
    }
    .ruleBox{
        background:var(--primary);
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding:2rem 1.5rem;
    }
    .description{
        font-size:1.3rem;
        text-align: center;
        margin-top:2rem;
        margin-bottom:2rem;
        color:#fff;
    }
    .seeMore{
        color:var(--blue3);
        font-size:1.3rem;
        text-decoration:underline;
        text-align: center;
        margin-top:2rem;
        margin-bottom:2rem;
    }
    .contentBox{
        color:#fff;
        font-size:1.3rem;
        margin-top:2rem;
    }
    .transferButton{
        background:var(--red2);
        padding:.7rem 1.5rem;
        color:#fff;
        border-radius:1rem;
        font-size:1.3rem;
    }
    .withdrawButton{
        background:var(--green4);
        padding:.7rem 1.5rem;
        color:#fff;
        border-radius:1rem;
        font-size:1.3rem;
        margin-right:1.5rem;
    }
    .optionList{
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:100%;
    }
    .optionLabelText{
        color:var(--link2);
        font-size:1.5rem;
        font-weight:bold;
    }
    .optionLabelValue{
        color:#fff;
        font-size:2rem;
        margin-left:1rem;
        font-weight:bold;
    }
    .optionButtonList{
        display:flex;
    }
    .optionLabel{
        
    }
    .optionBox{
        background:var(--sub6black);
        padding:2rem 1.5rem 1.5rem 1.5rem;
        margin-top:2rem;
        border-radius:1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .shareItem path{
        fill:var(--white2);
    }
    .shareItem svg{
        width:3rem;
        height:50%;
    }
    .shareItem{
        margin-right:1rem;
        display:flex;
        justify-content: center;
        align-items: center;
        width:4rem;
        height:4rem;
        background:var(--sub5black);
        border-radius:.5rem;
    }
    .shareList{
        display:flex;
        margin-top:1rem;
    }
    .copyButton svg{
        height:1.5rem;
        width:1.5rem;
    }
    .copyButton{
        display:flex;
        align-items: center;
        justify-content: center;
        background:var(--blue2);
        width:2.3rem;
        height:2.3rem;
        border-radius:.5rem;
    }
    .noticeSubTitle{
        color:#fff;
        margin-top:1rem;
        font-size:1.3rem;
    }
    .linkBox span{
        box-sizing:border-box;
        padding:0.5rem 1rem;
        display:block;
        font-size:1.3rem;
        color:var(--link);
    }
    .linkBox{
        margin-top:1rem;
        background:rgba(0,0,0,0.5);
        border-radius:.5rem;
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .noticeBox{
        height:fit-content;
        width:100%;
        background-repeat:no-repeat;
        background-size:100% 100%;
        margin-top:1.5rem;
        border-radius:1rem;
        box-sizing:border-box;
        padding:1.5rem 3rem;
        display:flex;
        flex-direction: column;
    }
    .noticeTitle{
        color:var(--noticetext);
        font-size:1.5rem;
    }
    .childBox{
        height:auto;
        overflow:hidden;
        width:100%;
    }
</style>