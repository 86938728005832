<template>
    <div class="inboxItemOut">
        <div
            class="inboxItem"
            v-if="!loading"
        >
            <span>{{formatDate(item.createTime)}}</span>
            <div class="inboxItemBox">
                <div class="msgTip" v-if="item.messageRead===0"></div>
                <div class="inboxItemTop">
                    <svg
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="200"
                        height="200"
                    >
                        <path
                            d="M869.2224 91.2896A51.2 51.2 0 0 0 819.2 51.2H204.8a51.2 51.2 0 0 0-50.0224 40.0896L61.44 512h217.0368a153.088 153.088 0 0 1 140.3904 92.16 101.2224 101.2224 0 0 0 186.2656 0 153.088 153.088 0 0 1 140.3904-92.16H962.56l-93.3376-420.7104zM358.4 153.6h307.2a51.2 51.2 0 1 1 0 102.4H358.4a51.2 51.2 0 0 1 0-102.4z m358.4 256H307.2a51.2 51.2 0 0 1 0-102.4h409.6a51.2 51.2 0 1 1 0 102.4z"
                            fill="#1F2A37"
                        ></path>
                        <path
                            d="M102.4 972.8h819.2a51.2 51.2 0 0 0 51.2-51.2v-307.2h-227.2768a50.688 50.688 0 0 0-46.4896 30.72 203.6736 203.6736 0 0 1-374.0672 0 50.688 50.688 0 0 0-46.4896-30.72H51.2v307.2a51.2 51.2 0 0 0 51.2 51.2z"
                            fill="#1F2A37"
                        ></path>
                    </svg>
                    <div class="emailRight">
                        <span class="emailHeader"> {{ item.title }} </span>
                        <div class="emailText">
                            <span :class="item.open?'show':'hide'">
                                {{ item.messageContent }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="inboxItemBottom">
                    <div class="inboxItemBottomOption" @click="goOpen">
                        <span>{{ item.open ? $t("Fold") : $t("UnFold") }}</span>
                        <svg
                            class="icon"
                            :style="
                                item.open
                                    ? 'transform:rotate(-90deg)'
                                    : 'transform:rotate(90deg)'
                                "
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="200"
                            height="200"
                        >
                            <path
                                d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                                fill="#8da5ca"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="skeleton" style="height:15rem" v-else>
        </div>
    </div>
</template>

<script>

    import {formatDate} from "../utils/dateUtil"

    export default {
        name:"InboxItem",
        props:{
            item:{
                type:Object,
                default: () => ({})
            },
            loading:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                formatDate,
            }
        },
        methods:{
            goOpen(){
                this.$emit("toOpen",this.item)
            }
        },
        mounted(){
        }
    }
</script>

<style scoped>
    .inboxItemOut{
        min-height:15rem;
        height:fit-content;
        width:100%;
        margin-bottom: 1.5rem;
        border-radius:1rem;
        overflow:hidden;
    }

    .msgTip {
        width: 1rem;
        height: 1rem;
        background: var(--red4);
        border: 1px solid var(--yellow2);
        border-radius: 50%;
        z-index: 10;
        top: 0;
        right: 0.5rem;
        position: absolute;
    }

    .inboxItemBottomOption > svg {
        height: 1.5rem;
        width: 1.5rem;
        margin-left: 2.5rem;
    }

    .inboxItemBottomOption > span {
        color: var(--disabled2);
        font-size: 1.6rem;
    }

    .inboxItemBottomOption {
        background: var(--sub19black);
        border-radius: 0.5rem;
        width: 14rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inboxItemBottom {
        background: var(--sub20black);
        height: 5rem;
        box-sizing: border-box;
        padding: 1rem;
        display: flex;
        flex-direction: row-reverse;
    }

    .emailText > span.hide {
        white-space: nowrap;
        display: block;
        max-height: 2.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s;
    }

    .emailText > span.show {
        white-space: normal;
        display: block;
        max-height: 100rem;
        overflow: inherit;
        text-overflow: inherit;
        transition: all 3s;
    }

    .emailText {
        word-break: break-all;
        width: 100%;
        color: #fff;
        font-size: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0.5rem;
    }

    .emailHeader {
        color: var(--disabled2);
        font-size: 1.5rem;
    }

    .emailRight {
        /* display: flex;
        flex-direction: column; */
        margin-left: 3rem;
        width: 70%;
        flex: 1;
        justify-content: space-around;
    }

    .inboxItemTop path {
        fill: var(--disabled2);
    }

    .inboxItemTop svg {
        height: 5rem;
        width: 5rem;
    }

    .inboxItemTop {
        display: flex;
        box-sizing: border-box;
        padding: 2rem 4rem 0rem 3rem;
        background: var(--popover);
    }

    .inboxItemBox {
        width: 100%;
        margin-top: 1rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .inboxItem {
        display: flex;
        flex-direction: column;
    }

    .inboxItem > span {
        color: var(--disabled2);
        font-size: 1.5rem;
    }
</style>