<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="classList">
                <div @click="changeRoute(item,index)" :class="item.active ? 'classItem active' : 'classItem'" v-for="(item,index) in classList" :key="index">
                    <img :src="item.img" />
                    <span v-text="item.name"></span>
                </div>
            </div>
            <template v-if="classList[0].active">
                <div class="balanceBox">
                    <img :src="Gold" />
                    <span>0.00</span>
                </div>
                <div class="levelBox" :style="'background-image:url(' + VIPBG + ')'">
                    <div class="levelRankBox" :style="'background-image:url(' + RankBg + ')'">
                        <span class="levelText">{{$store.state.user?.user?.level}}</span>
                        <span class="levelDes">{{ $t("VIP") }}</span>
                    </div>
                    <div class="currentLevel" style="padding-top:2.72rem;">
                        <div class="currentFields">
                            <div class="currentFieldw">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldWhiteLabel">Slot</span>
                            </div>
                            <div class="currentFieldw">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldWhiteLabel">In-House</span>
                            </div>
                            <div class="currentFieldw">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldWhiteLabel">Table</span>
                            </div>
                            <div class="currentFieldw">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldWhiteLabel">Live</span>
                            </div>
                            <div class="currentFieldw">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldWhiteLabel">Fish</span>
                            </div>
                        </div>
                    </div>
                    <div class="currentLevel">
                        <div class="currentTitle">
                            {{ $t("Current") }}V0
                        </div>
                        <div class="currentFields">
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldValue">0.40%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldValue">0.50%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldValue">0.40%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldValue">0.40%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="currentFieldValue">0.40%</span>
                            </div>
                        </div>
                    </div>
                    <div class="transitionBox">
                        <Transition :margin="5.1"></Transition>
                    </div>
                    <div class="currentLevel">
                        <div class="nextTitle">
                            {{ $t("Next") }}V1
                        </div>
                        <div class="currentFields">
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="nextFieldValue">0.40%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="nextFieldValue">0.55%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="nextFieldValue">0.40%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="nextFieldValue">0.40%</span>
                            </div>
                            <div class="currentField">
                                <span class="currentFieldLabel">&nbsp;</span>
                                <span class="nextFieldValue">0.40%</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="tableTitle">
                    Ratio of Bet Bonus
                </div>
                <div class="table">
                    <div class="tableHeader">
                        <div class="tableField" style="width:16.66%">
                            Level
                        </div>
                        <div class="tableField" style="width:16.66%">
                            Slot
                        </div>
                        <div class="tableField" style="width:16.66%">
                            In-House
                        </div>
                        <div class="tableField" style="width:16.66%">
                            Table
                        </div>
                        <div class="tableField" style="width:16.66%">
                            Live
                        </div>
                        <div class="tableField" style="width:16.66%">
                            Fish
                        </div>
                    </div>
                    <div class="tableBody">
                        <template v-if="table.length == 0">
                            <div style="height:25rem;">
                                <Empty></Empty>
                            </div>
                        </template>
                        <template v-else>
                            <div class="tableBodyReal">
                                <div class="tableBodyRow" v-for="(item,index) in table" :key="index">
                                    <div class="tableBodyCol" style="width:16.66%">
                                        <div class="tableLevelRank">
                                            <img :src="item.img" />
                                            <span class="tableLevelRankText" v-text="item.level"></span>
                                            <span class="tableLevelRankDes">VIP</span>
                                        </div>
                                        <div class="currentLevelBox" v-if="index == 0">
                                            {{ $t("Current") }} {{ $t("Level") }}
                                        </div>
                                    </div>
                                    <div class="tableBodyCol" style="width:16.66%">
                                        <span class="tableText">
                                            0.40%
                                        </span>
                                    </div>
                                    <div class="tableBodyCol" style="width:16.66%">
                                        <span class="tableText">
                                            0.50%
                                        </span>
                                    </div>
                                    <div class="tableBodyCol" style="width:16.66%">
                                        <span class="tableText">
                                            0.40%
                                        </span>
                                    </div>
                                    <div class="tableBodyCol" style="width:16.66%">
                                        <span class="tableText">
                                            0.40%
                                        </span>
                                    </div>
                                    <div class="tableBodyCol" style="width:16.66%">
                                        <span class="tableText">
                                            0.40%
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <Table :data="[]" :column="ratioColumn"></Table>
            </template>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

    import Footer from '@/components/Footer.vue';
    import Transition from '@/components/Transition.vue';

    import Chip from "../assets/img/chip.png"
    import Ratio from "../assets/img/ratio.png"

    import Gold from "../assets/img/gold.png";
    import VIPBG from "../assets/img/VIPBG.png"
    import Empty from "../components/Empty.vue"
    import Table from "../components/Table.vue"

    import RankBg from "../assets/img/rankBG.png"
    import RankBg2 from "../assets/img/rank2BG.png"
    import RankBg3 from "../assets/img/rank3BG.png"

    export default {
        name:"BetBonus",
        data(){
            return {
                Gold,
                VIPBG,
                RankBg,
                ratioColumn:[
                    {
                        name:"Date"
                    },
                    {
                        name:"TotalBonus"
                    },
                    {
                        name:"SlotBonus"
                    },
                    {
                        name:"In-HouseBonus"
                    },
                    {
                        name:"TableBonus"
                    },
                    {
                        name:"LiveBonus"
                    },
                ],
                table:[
                    {
                        level:0,
                        current:false,
                        img:RankBg
                    },
                    {
                        level:1,
                        current:false,
                        img:RankBg
                    },
                    {
                        level:2,
                        current:false,
                        img:RankBg
                    },
                    {
                        level:3,
                        current:false,
                        img:RankBg2
                    },
                    {
                        level:4,
                        current:false,
                        img:RankBg2
                    },
                    {
                        level:5,
                        current:false,
                        img:RankBg3
                    }
                ],
                classList:[
                    {
                        name:"Bet Bonus",
                        img:Chip,
                        active:true,
                    },
                    {
                        name:"RATIO",
                        img:Ratio,
                        active:false,
                    },
                ]
            }
        },
        components:{
            Footer,
            Transition,
            Empty,
            Table
        },
        methods:{
            changeRoute(item,index){
                this.classList.forEach((item2, i) => {
                    if (i === index) {
                        item2.active = true;
                    } else {
                        item2.active = false;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .tableText{
        color:var(--white5);
        font-size:1.5rem;
    }
    .currentLevelBox{
        background-color:var(--green6);
        color:#fff;
        box-sizing:border-box;
        padding:.2rem .5rem;
        border-radius:.8rem;
        font-size:.9rem;
        font-weight:bold;
        position:absolute;
        white-space: nowrap;
        z-index:20;
        top:.5rem;
    }
    .tableBodyCol{
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        position:relative;
    }
    .tableLevelRankDes{
        color:#fff;
        font-size:1rem;
        font-weight:bold;
        position:relative;
        z-index:1;
    }
    .tableLevelRankText{
        font-size:1.8rem;
        font-weight:bold;
        color:#fff;
        position:relative;
        z-index:1;
    }
    .tableLevelRank img{
        position:absolute;
        height:6rem;
        z-index:0;
        bottom:0rem;
    }
    .tableLevelRank{
        width:5rem;
        height:5rem;
        position:relative;
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding-top:.8rem;
    }
    .tableBodyRow{
        height:8rem;
        background:var(--sub29black);
        margin-bottom:.5rem;
        display:flex;
        align-items: center;
    }
    .tableBodyReal{
        display:flex;
        flex-direction: column;
        height:fit-content;
        width:100%;
    }
    .tableBody{
        min-height:25rem;
        height:fit-content;
    }
    .tableField{
        color:var(--disabled6);
        font-size:1.4rem;
        font-weight:bold;
        text-align: center;
    }
    .tableHeader{
        display:flex;
        height:5rem;
        margin-top:1rem;
        align-items: center;
    }
    .table{
        height:fit-content;
        display:flex;
        flex-direction: column;
    }
    .tableTitle{
        color:#fff;
        font-size:2.5rem;
        /* font-weight:bold; */
        margin-top:4rem;
    }
    .currentFieldWhiteLabel{
        color:#fff;
        font-size:1.5rem;
        font-weight:bold;
        white-space:nowrap
    }
    .transitionBox{
        width:15rem;
    }
    .levelDes{
        color:#fff;
        font-size:1.5rem;
        font-weight:bold;
    }
    .levelText{
        font-size:4rem;
        font-weight:bold;
        color:#fff;
    }
    .levelRankBox{
        box-sizing:border-box;
        padding-top:1.5rem;
        position:absolute;
        width:10rem;
        height:10rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        left:.7rem;
        top:6.5rem;
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .nextFieldValue{
        color:var(--disabled21);
        font-size:1.8rem;
        font-weight:bold;
    }
    .currentFieldValue{
        color:var(--green5);
        font-size:1.8rem;
        font-weight:bold;
    }
    .currentFieldLabel{
        font-size:1.5rem;
        font-weight:bold;
        color:#fff;
    }
    .currentField{
        display:flex;
        flex-direction: column;
        margin-top:.73rem;
    }
    .currentFieldw{
        display:flex;
        flex-direction: column;
        margin-top:.9rem;
    }
    .currentFields{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .nextTitle{
        background-color:var(--orange);
        color:#fff;
        box-sizing:border-box;
        padding:.2rem .5rem;
        border-radius:.8rem;
        font-size:1.3rem;
        font-weight:bold;
    }
    .currentTitle{
        background-color:var(--green6);
        color:#fff;
        box-sizing:border-box;
        padding:.2rem .5rem;
        border-radius:.8rem;
        font-size:1.3rem;
        font-weight:bold;
    }
    .currentLevel{
        display:flex;
        flex-direction: column;

    }
    .levelBox{
        position:relative;
        display:flex;
        height:fit-content;
        width:100%;
        margin-top:1rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        box-sizing: border-box;
        padding:1.5rem 2rem;
        padding-left:12rem;
    }
    .balanceBox span{
        color:#fff;
        font-size:2.3rem;
        font-weight:bold;
        margin-left:2rem;
    }
    .balanceBox img{
        height:5rem;
    }
    .balanceBox{
        background:var(--primary);
        display:flex;
        box-sizing:border-box;
        padding:2rem 1rem;
        border-radius:1rem;
        margin-top:1rem;
        align-items: center;

    }
    .box{
        background: var(--subblack);
        box-sizing:border-box;
        padding-top:8rem;
        padding-bottom:8rem;
        box-sizing:border-box;
        padding:1rem 1rem;
    }
    .classList{
        display:flex;
        background-image: linear-gradient(to top,var(--sub9black),var(--subblack));
        height:8rem;
        width:100%;
        border-radius:1rem;
        overflow:hidden;
        margin-top:1rem;
    }
    .classItem{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        width:50%;
        border-radius:1rem;
    }
    .classItem img{
        height:2.5rem;
    }
    .classItem span{
        font-size:1.5rem;
        margin-top:1rem;
        color:var(--disabled8);
        font-weight:bold;
    }
    .classItem.active{
        background-image: linear-gradient(to top,var(--sub10black),var(--subblack));
    }
    .classItem.active span{
        color:#fff;
    }
</style>
