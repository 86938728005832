// store/token.js
import {login, getUserInfo,logout} from "@/api/user.js";
import Vue from 'vue';

const state = {
    token: localStorage.getItem('token') || null,
    language: localStorage.getItem("localStorage") || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
    avatar:JSON.parse(localStorage.getItem("avatar")) || null,
    agentid:localStorage.getItem('agentid') || null,
    license:localStorage.getItem('license') || null,
    currentCategoryBg:localStorage.getItem('classBg') || "",

    game:JSON.parse(localStorage.getItem('game')) || [], // 游戏列表
    category:JSON.parse(localStorage.getItem('category')) || [], // 游戏列表
    stationimglist:JSON.parse(localStorage.getItem('stationImgList')) || [], // 游戏列表
    historyList:JSON.parse(localStorage.getItem("history")) || [], // 历史列表
    tabbarList:JSON.parse(localStorage.getItem("tabbarList")) || [], // tabbar列表
    noticeList:JSON.parse(localStorage.getItem("notice")) || [], // notice列表
    favoriteList:JSON.parse(localStorage.getItem("favorite")) || [], // favorite列表
    noticeAllList:JSON.parse(localStorage.getItem("noticeAllList")) || [], // 弹窗，promotionlist等
    ajuda:JSON.parse(localStorage.getItem("ajuda")) || [],

    recent:JSON.parse(localStorage.getItem("recent")) || null, // 是否开启最近游戏记录
    messageCount:JSON.parse(localStorage.getItem("usermessage")) || 0, // 是否开启最近游戏记录
    depositInfo:JSON.parse(localStorage.getItem("depositinfo")) || null, // 充值的二十分钟数据
    payconfig:JSON.parse(localStorage.getItem("payconfig")) || null // 充值配置信息
};

const mutations = {
    
    SET_AJUDA(state,list){
        state.ajuda = list;
        localStorage.setItem("ajuda",JSON.stringify(list));
    },
    SET_CURRENT_CATEGORY_BG(state,src){
        state.currentCategoryBg = src;
        localStorage.setItem("classBg",src);
    },
    SET_NOTICE_LIST(state,res){
        state.noticeAllList = res;
        localStorage.setItem("noticeAllList",JSON.stringify(res));
    },
    SET_PAY_CONFIG(state,res){
        state.payconfig = res;
        localStorage.setItem("payconfig",JSON.stringify(res));
    },
    SET_FAVORITE_LIST(state,list){
        state.favoriteList = list;
        localStorage.setItem("favorite",JSON.stringify(list));
    },
    SET_LIVE_CHAT_LICENSE(state,license){
        state.license = license;
        localStorage.setItem("license",license);
    },
    SET_AGENT_ID(state,agentid){
        state.agentid = agentid;
        localStorage.setItem("agentid",agentid);
    },
    SET_DEPOSIT_INFO(state,depositinfo){
        state.depositInfo = depositinfo;
        localStorage.setItem("depositinfo",JSON.stringify(depositinfo));
    },
    SET_MESSAGE_COUNT(state,usermessage){
        state.messageCount = usermessage;
        localStorage.setItem("usermessage",JSON.stringify(usermessage));
    },
    SET_RECENT(state,recent){
        state.recent = recent;
        localStorage.setItem("recent",JSON.stringify(recent));
    },
    SET_NOTICE(state,notice){
        state.noticeList = notice;
        localStorage.setItem("notice",JSON.stringify(notice));
    },
    SET_TABBAR(state,tabbar){
        state.tabbarList = tabbar;
        localStorage.setItem("tabbarList",JSON.stringify(tabbar));
    },
    SET_AVATAR(state,avatar){
        state.avatar = avatar;
        localStorage.setItem("avatar",avatar);
    },
    SET_GAME(state, game){
        state.game = game;
        localStorage.setItem('game', JSON.stringify(game));
    },
    SET_CATEGORY(state,category){
        state.category = category;
        localStorage.setItem('category', JSON.stringify(category));
    },
    SET_STATIONIMG(state,stationimglist){
        state.stationimglist = stationimglist;
        localStorage.setItem('stationImgList', JSON.stringify(stationimglist));
    },
    SET_HISTORY(state,history){
        state.historyList = history;
        localStorage.setItem('history', JSON.stringify(history));
    },
    SET_TOKEN(state, token) {
        state.token = token;
        localStorage.setItem('token', token);
    },
    CLEAR_TOKEN(state) {
        state.token = null;
        localStorage.removeItem('token');
    },
    CLEAR_DEPOSIT_INFO(){
        state.depositInfo = null;
        localStorage.removeItem('depositinfo');
    },
    SET_LANGUAGE(state, language) {
        state.language = language;
        localStorage.setItem('language', language);
    },
    CLEAR_LANGUAGE(state) {
        state.language = null;
        localStorage.removeItem('language');
    },
    SET_USER(state, user) {
        state.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    },
    CLEAR_USER(state) {
        state.user = null;
        localStorage.removeItem('user');
    },
};

const actions = {
    
    setAjuda({commit},list){
        commit('SET_AJUDA',list);
    },
    setCurrentCategoryBg({commit},src){
        commit('SET_CURRENT_CATEGORY_BG',src);
    },
    setAllNoticeList({commit},res){
        commit('SET_NOTICE_LIST',res);
    },
    setPayConfig({commit},res){
        commit('SET_PAY_CONFIG',res);
    },
    setFavoriteList({commit},list){
        commit('SET_FAVORITE_LIST',list);
    },
    setLiveChatLicense({commit},license) {
        commit('SET_LIVE_CHAT_LICENSE',license);
    },
    setAgentId({commit},agentid) {
        commit('SET_AGENT_ID',agentid);
    },
    clearDepositInfo({commit}) {
        commit('CLEAR_DEPOSIT_INFO');
    },
    setDepositInfo({commit},depositinfo){
        commit("SET_DEPOSIT_INFO",depositinfo)
    },
    readMessage({ commit, state }) {
        if (state.messageCount > 0) {
            const newMessageCount = state.messageCount - 1;
            commit('SET_MESSAGE_COUNT', newMessageCount);
        }
    },
    setUserMessage({commit},usermessage){
        commit("SET_MESSAGE_COUNT",usermessage)
    },
    setRecent({commit},recent){
        commit("SET_RECENT",recent)
    },
    setNotice({commit},notice){
        commit("SET_NOTICE",notice)
    },
    setTabbar({commit},tabbar){
        commit("SET_TABBAR",tabbar)
    },
    setAvatar({commit},avatar){
        commit("SET_AVATAR",avatar)
    },
    setCategory({commit},category){
        commit("SET_CATEGORY",category)
    },
    setGame({commit},games){
        commit("SET_GAME",games)
    },
    setStationImgList({commit},stationImg){
        commit("SET_STATIONIMG",stationImg)
    },
    setHistory({commit},history){
        commit("SET_HISTORY",history)
    },
    setToken({commit}, token) {
        commit('SET_TOKEN', token);
    },
    clearToken({commit}) {
        commit('CLEAR_TOKEN');
    },
    setLanguage({commit}, language) {
        commit('SET_LANGUAGE', language);
    },
    clearLanguage({commit}) {
        commit('CLEAR_LANGUAGE');
    },
    setUser({commit}, user) {
        commit('SET_USER', user);
    },
    clearUser({commit}) {
        commit('CLEAR_USER');
    },
    // 登录
    Login({commit}, userInfo) {
        const account = userInfo.account.trim()
        const password = userInfo.password
        const type = userInfo.type
        return new Promise((resolve, reject) => {
            login(account, password, type).then(res => {
                if(res.code == 500){
                    Vue.prototype.$message({
                        message: res.msg
                    });
                    reject(error)
                }else{
                    let data = res.data
                    commit('SET_TOKEN', data.accessToken)
                    resolve()
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 获取用户信息
    GetInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            getUserInfo().then(res => {
                if (res.code === 200) {
                    commit('SET_USER', res.data);
                    resolve(res)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 退出系统
    LogOut({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout(state.token).then((res) => {
                commit('CLEAR_TOKEN');
                commit('CLEAR_MESSAGE');
                commit('CLEAR_USER');
                commit('CLEAR_LANGUAGE');
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
};

const getters = {
    isAuthenticated(state) {
        return state.token !== null;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
