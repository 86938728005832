<template>
  <div class="viewBoxGlobal">
    <div class="box" v-if="categoryId!==null">
      <div class="titleBox" :style="'background-image:url(' + currentCategoryBg + ')'">
        <div class="goBack" @click="goBack">
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
            <path d="M947.4 864C893.2 697.7 736.2 578.9 551 575.5c-23.1-0.4-44.9 0.1-65.6 1.5v164.3c0.1 0.5 0.2 1 0.2 1.5 0 4-3.3 7.3-7.3 7.3-2.7 0-5-1.4-6.2-3.5v0.7L68.8 465.4h2.1c-4 0-7.3-3.3-7.3-7.3 0-2.9 1.7-5.4 4.1-6.6L472 169v0.7c1.3-2.1 3.6-3.5 6.2-3.5 4 0 7.3 3.3 7.3 7.3 0 0.5-0.1 1-0.2 1.5v159.4c18.5-0.9 37.9-1.2 58.3-0.8 230.1 3.9 416.7 196.9 416.7 427.1 0.1 35.5-4.5 70.2-12.9 103.3z m-462-704.4v0.2h-0.4l0.4-0.2z m0 596.9l-0.3-0.2h0.3v0.2z" fill=""></path>
          </svg>
        </div>
        <span v-text="categoryName"></span>
      </div>
      <div class="game-box">
        <div class="searchBox">
          <img :src="Search"/>
          <input v-model="searchKey" placeholder="Search"/>
        </div>
        <div class="game-list">
          <template v-if="myGameFilterList.filter(item => (searchKey == '' || toLowerCase(item.gameName).indexOf(toLowerCase(searchKey)) != -1)).length > 0">
            <GameItem v-for="(game,gameIndex) in myGameFilterList.filter(item => (searchKey == '' || toLowerCase(item.gameName).indexOf(toLowerCase(searchKey)) != -1))"
              :key="gameIndex"
              :item="game"
              :click="toGame">
            </GameItem>
          </template>
          <template v-else>
            <div style="height:30rem;width:100%;display:flex;align-items: center;justify-content: center;">
              <Empty></Empty>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <Modal
        :visible.sync="confirmVisible"
        :gradient="true"
    >
      <span class="confirmDes">Less than 1 chips,you need to recharge</span>
      <div class="confirmOption">
        <div class="cancelButton" @click="closeConfirm">
          Cancel
        </div>
        <div class="confirmButton" @click="queryConfirm">
          Ok
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import Footer from "../components/Footer.vue"
import Modal from "../components/Modal.vue"
import Search from "../assets/img/search.png"
import GameItem from "@/components/GameItem.vue"
import Empty from '@/components/Empty.vue';
import { toLowerCase } from "@/utils/dateUtil.js";
import { mapState } from 'vuex';

export default {
  name: "Classification",
  computed:{
    myGameFilterList(){
      return this.gameList.filter(g=>g.categoryId==this.categoryId);
    },
    ...mapState({
      currentCategoryBg: state => state.user.currentCategoryBg,
    }),
  },
  data() {
    return {
      toLowerCase,
      Search,
      confirmVisible: false,
      gameList:[],
      categoryId:null,
      categoryName:"",
      searchKey:"", // 搜索关键字
    }
  },
  components: {
    Footer,
    Modal,
    GameItem,
    Empty
  },
  methods: {
    goBack() {
      this.$router.push("/home");
    },
    changeCollection(index, index2) {
      this.gameList[index2].collection = !this.gameList[index2].collection;
    },
    queryConfirm() {
      this.$router.push("/deposit?mode=deposit")
    },
    closeConfirm() {
      this.confirmVisible = false;
    },
    init(){
      this.categoryId = this.$route.query.id;
      this.categoryName = this.$route.query.name;
      this.gameList = JSON.parse(localStorage.getItem('game'));
    },
    toGame(item) {
        const game = item;

        //这里需要判断是否已经登录，如果没有登录跳转到登录页面
        if (!this.$store.state.user.token) {
            this.$router.push({path: "/login", query: {mode: "login"}});
            return;
        }

        //这里需要判断余额是否足够
        if (this.$store.state.user?.user?.balance < game.minBalanceEnter) {
            this.confirmVisible = true;
            return;
        }

        this.setHistory(game.gameId);

        const gameParams = {channel: game.channelBean, gameId: game.gameId, gameCode: game.gameCode, lang: ""};
        this.$router.push({
            path: '/other',
            query: {data: JSON.stringify(gameParams), type: "game",intype:'folder',folderid:this.categoryId,foldername:this.categoryName}
        });
    },
    setHistory(gameId) {
      try {
        // 从 localStorage 中获取历史记录
        let history = JSON.parse(localStorage.getItem('history')) || [];
        // 过滤掉所有具有相同 id 的记录
        history = history.filter(item => item.id !== gameId);
        // 添加新记录到数组
        history.push({ time: new Date().getTime(), id: gameId });
        // 按时间降序排序，确保最新的记录在前面
        history.sort((a, b) => b.time - a.time);
        // 只保留最新的五个记录
        history = history.slice(0, 5);
        // 将更新后的数组存回 localStorage
        this.$store.dispatch('setHistory',history)
      } catch (e) {
        console.error('Error parsing history from localStorage:', e);
      }
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>
.confirmButton {
  color: #fff;
  width: 12rem;
  background: var(--green4);
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
}

.cancelButton {
  color: #fff;
  width: 12rem;
  background: var(--red2);
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
}

.confirmOption {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  box-sizing: border-box;
  padding: 0 3rem;
}

.confirmDes {
  color: var(--link8);
  font-size: 1.7rem;
  font-weight: bold;
}

.classItemCollection svg {
  width: 1.6rem;
  height: 1.6rem;
}

.classItemCollection {
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center
}

.game-item {
  height: 18rem;
  width: calc(33% - 1rem);
  margin-right: 1.5rem;
  position: relative;
}

.game-item:nth-child(3n) {
  margin-right: 0;
}

.game-item img {
  width: 100%;
  height: 100%;
}

.game-box {
  margin-top: 2rem;
  box-sizing: border-box;
  padding: 0 1rem;
}

.collectioned path {
  fill: var(--yellow5);
}

.uncollection path {
  fill: rgba(255, 255, 255, .7)
}

.searchBox input::placeholder {
  color: var(--disabled2);
}

.searchBox input {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  margin-left: 1.5rem;
  background: none;
  outline: none;
  border: none;
}

.searchBox img {
  height: 2.5rem;
}

.searchBox {
  border: 1px solid var(--disabled15);
  box-sizing: border-box;
  height: 5rem;
  border-radius: 1rem;
  align-items: center;
  display: flex;
  padding: 0 2rem;
}

.game-list {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom:1rem;
}

.box {
  background: var(--subblack);
  box-sizing: border-box;
  padding-top: 1rem;
}

.titleBox {
  background: var(--sub28black);
  width: 100%;
  height: 10rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  display: flex;
  align-items: center;
}

.titleBox > span {
  color: #fff;
  font-weight: bold;
  font-size: 2.5rem;
  margin-left: 2rem;
}

.goBack {
  width: 5rem;
  height: 4rem;
  background: var(--primary);
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.goBack > svg {
  height: 2.5rem;
  width: 3rem;
}

.goBack > svg > path {
  fill: var(--disabled4);
}
</style>
