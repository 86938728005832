<template>
    <div :class=" mode=='dark' ? 'emptyBox' : 'lightBox'">
        <img :style="color ? 'filter:' + hexColor : ''" :src="mode == 'dark' ? Empty : EmptyLight" />
        <span :style="color ? 'color:' + color + '' : ''" class="emptyText">{{ $t("NoData") }}</span>
    </div>
</template>

<script>

    import Empty from "../assets/img/empty.png"
    import EmptyLight from "../assets/img/emptylight.png"

    export default {
        name:"Empty",
        props:{
            mode:{
                type: String,
                default: 'dark' // 默认值
            },
            color:{
                type:String,
                default:"",
            }
        },
        computed:{
            hexColor(){
                let hex = this.color;
                // 将十六进制颜色值转换为 RGB
                const hexToRgb = hex => {
                    let r = parseInt(hex.slice(1, 3), 16);
                    let g = parseInt(hex.slice(3, 5), 16);
                    let b = parseInt(hex.slice(5, 7), 16);
                    return { r, g, b };
                };
                
                const rgb = hexToRgb(hex);

                // 使用算法计算与该颜色最接近的滤镜效果
                let sepia = 100;  // 固定为100% sepia
                let saturate = 1000;  // 增加饱和度
                let hueRotate = (Math.atan2(rgb.g - rgb.b, rgb.r - rgb.b) * 180 / Math.PI) || 0;

                // 返回滤镜字符串
                return `sepia(${sepia}%) saturate(${saturate}%) hue-rotate(${hueRotate}deg)`;
            }
        },
        data(){
            return {
                Empty,
                EmptyLight
            }
        },
        methods:{

        }
    }
</script>

<style>

    .lightBox{
        display:flex;
        width:100%;
        height:100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .lightBox>img{
        width:5rem;
    }
    .lightBox>span{
        color:var(--disabled2);
        margin-top:1rem;
        font-size:1.5rem;
    }

    .emptyBox{
        display:flex;
        width:100%;
        height:100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .emptyBox>img{
        width:5rem;
    }
    .emptyBox>span{
        color:var(--popover);
        margin-top:1rem;
        font-size:1.5rem;
    }
</style>