<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="classList">
                <div @click="changeRoute(item,index)" :class="item.active ? 'classItem active' : 'classItem'" v-for="(item,index) in classList" :key="index">
                    <img :src="item.img" />
                    <span v-text="item.name"></span>
                </div>
            </div>
            <div class="viewBox">
                <router-view>
                </router-view>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Footer from "../components/Footer.vue"

    import Invite from "../assets/img/inviteI.png"
    import Refer from "../assets/img/referI.png"
    import Member from "../assets/img/memberI.png"
    import Bonus from "../assets/img/bonusI.png"

    export default {
        name:"Refer",
        data(){
            return {
                classList:[
                    {
                        name:"Invite",
                        path:"/refer/invite",
                        img:Invite,
                        active:true,
                    },
                    {
                        name:"Refer",
                        path:"/refer/referc",
                        img:Refer,
                        active:false,
                    },
                    {
                        name:"Member",
                        path:"/refer/member",
                        img:Member,
                        active:false,
                    },
                    {
                        name:"Bonus",
                        path:"/refer/bonus",
                        img:Bonus,
                        active:false,
                    }
                ]
            }
        },
        components:{
            Footer
        },  
        methods:{
            changeRoute(item,index){
                this.classList.forEach((item2, i) => {
                    if (i === index) {
                        item2.active = true;
                    } else {
                        item2.active = false;
                    }
                })
                this.$router.push(item.path)
            }
        },
        watch:{
            $route(to,from){
                this.classList.forEach(item => {
                    if(item.path == to.path){
                        item.active = true;
                    }else{
                        item.active = false;
                    }
                })
            }
        },
        mounted(){
        },
    }
</script>

<style scoped>
    .box{
        background: var(--subblack);
        box-sizing:border-box;
        padding-top:8rem;
        padding-bottom:8rem;
        box-sizing:border-box;
        padding:1rem 1rem;
    }
    .classList{
        display:flex;
        background-image: linear-gradient(to top,var(--sub9black),var(--subblack));
        height:8rem;
        width:100%;
        border-radius:1rem;
        overflow:hidden;
        margin-top:1rem;
    }
    .classItem{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        width:25%;
        border-radius:1rem;
    }
    .classItem img{
        height:3rem;
    }
    .classItem span{
        font-size:1.5rem;
        margin-top:1rem;
        color:var(--disabled8);
        font-weight:bold;
    }
    .classItem.active{
        background-image: linear-gradient(to top,var(--sub10black),var(--subblack));
    }
    .classItem.active span{
        color:#fff;
    }
    .viewBox{
        height:fit-content;
        overflow:hidden;
        width:100%;
    }
</style>