<template>
    
    <div class="TranscationModal">

        <div class="title">
            <div style="width:2rem"></div>
            <span v-text="'Adicionar PIX'"></span>
            <svg @click.stop="closeModal" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path></svg>
        </div>

        <div class="formBox">
            
            <div class="formItem">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                    <path fill="#58667a" d="M373 411c-28.5 0-51.7 23.3-51.7 52s23.2 52 51.7 52 51.7-23.3 51.7-52-23.2-52-51.7-52z"></path>
                    <path fill="#58667a" d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zM608 420c0-4.4 1-8 2.3-8h123.4c1.3 0 2.3 3.6 2.3 8v48c0 4.4-1 8-2.3 8H610.3c-1.3 0-2.3-3.6-2.3-8v-48z m-86 253h-43.9c-4.2 0-7.6-3.3-7.9-7.5-3.8-50.5-46-90.5-97.2-90.5s-93.4 40-97.2 90.5c-0.3 4.2-3.7 7.5-7.9 7.5H224c-4.6 0-8.2-3.8-8-8.4 2.8-53.3 32-99.7 74.6-126.1-18.1-19.9-29.1-46.4-29.1-75.5 0-61.9 49.9-112 111.4-112s111.4 50.1 111.4 112c0 29.1-11 55.5-29.1 75.5 42.7 26.5 71.8 72.8 74.6 126.1 0.4 4.6-3.2 8.4-7.8 8.4z m278.9-53H615.1c-3.9 0-7.1-3.6-7.1-8v-48c0-4.4 3.2-8 7.1-8h185.7c3.9 0 7.1 3.6 7.1 8v48h0.1c0 4.4-3.2 8-7.1 8z">
                    </path>
                </svg>
                <input type="text" v-model="form.realName" placeholder="Digite seu nome verdadeiro" />
            </div>

            <el-popover
                placement="bottom-start"
                trigger="click"
                popper-class="formPopover"
                v-model="select"    
            >
                <div class="formList">
                    <div :class="form.accountType == 'phone' ? 'formListItem active' : 'formListItem'" @click="toHide('phone')">
                        PIX-TELEFONE
                    </div>
                    <div :class="form.accountType == 'email' ? 'formListItem active' : 'formListItem'" @click="toHide('email')">
                        PIX-EMAIL
                    </div>
                    <div :class="form.accountType == 'cpf' ? 'formListItem active' : 'formListItem'" @click="toHide('cpf')">
                        PIX-CPF
                    </div>
                </div>
                <div class="formItem" slot="reference">
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M256 256a256 256 0 1 0 512 0 256 256 0 1 0-512 0zM512 576C243.2 576 19.2 761.6 0 992c0 19.2 12.8 32 32 32h953.6c19.2 0 32-12.8 32-32-12.8-230.4-236.8-416-505.6-416z" fill="#58667a"></path>
                    </svg>
                    <input type="text" disabled :value="typeLabel(form.accountType)" />
                    <svg :style="select ? 'selectmargin-left:.5rem;transform:rotate(180deg)' : 'selectmargin-left:.5rem'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M281.6 409.6l221.866667 268.8c4.266667 4.266667 12.8 4.266667 17.066666 0l221.866667-268.8c4.266667-8.533333 0-17.066667-8.533333-17.066667H290.133333c-12.8 0-17.066667 8.533333-8.533333 17.066667z" fill="#495669"></path>
                    </svg>
                </div>
            </el-popover>

            <div class="formItem" v-show="form.accountType == 'email' || form.accountType == 'phone'">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                    <path d="M484.8 585c10.8-10.8 29.4-10.8 40.2 0l154 154c28.4 28.4 66.2 44 106.2 44h30.2l-194.2 194.2c-60.6 59-159 59-219.6 0l-195-194.8h18.6c40 0 77.8-15.6 106.2-44l153.4-153.4z m40.2-147.2c-12.8 11-29.2 11.2-40.2 0l-153.4-153.4c-28.4-30.2-66.2-44-106.2-44h-18.6l194.8-194.88c60.8-60.692 159.2-60.692 219.8 0l194.4 194.28h-30.4c-40 0-77.8 15.6-106.2 44l-154 154z m-299.8-152.4c27.6 0 53 11.2 74.2 30.8l153.4 153.4c14.4 12.6 33.2 21.6 52.2 21.6 18.8 0 37.6-9 52-21.6l154-154c19.6-19.4 46.6-30.6 74.2-30.6h75.4l116.6 116.6c60.6 60.6 60.6 159 0 219.6l-116.6 116.6h-75.4c-27.6 0-54.6-11.2-74.2-30.8l-154-154c-27.8-27.8-76.4-27.8-104.2 0.2l-153.4 153.2c-21.2 19.6-46.6 30.8-74.2 30.8H161.56l-116.04-116c-60.692-60.6-60.692-159 0-219.6l116.04-116.2h63.64z" fill="#1afa29">
                    </path>
                </svg>
                <input type="text" v-model="form.account" placeholder="Introduza a sua conta PIX" />
            </div>

            <div class="formItem">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                    <path d="M960 256v64H64v-64c0-35.2 28.8-64 64-64h768c35.2 0 64 28.8 64 64z m0 128v384c0 35.2-28.8 64-64 64H128c-35.2 0-64-28.8-64-64V384h896zM256 640H128v32h128v-32z m128-64H128v32h256v-32z" fill="#58667a"></path>
                </svg>
                <input type="text" v-model="form.cpf" placeholder="Introduza o numero de 11 digitos do cpf" />
            </div>

        </div>
        
        <div class="formButton" :style="canPush ? '' : 'filter: brightness(80%);'" @click="confirm">
            Congirmar
            <div style="margin-left:0.3rem" v-loading="isPush"></div>
        </div>
    </div>

</template>

<script>

    import { AddTranscationInfo } from "@/api/deposit.js";

    export default {
        name:"TranscationInfoModal",
        data(){
            return {
                select:false,
                isPush:false,
                form:{
                    transactionPassword:"", // 交易密码
                    account:"", // 本次添加的账号
                    accountType:"phone", // 账户类型
                    realName:"", // 真实姓名
                    cpf:"", // cpf号码
                }
            }
        },
        props:{
            password:{
                type:Number
            },
            visible:{
                type:Boolean
            }
        },
        computed: {
            canPush() {
                const { transactionPassword, account, accountType, realName, cpf } = this.form;
                
                // 当 type 为 "phone" 或 "email" 时，account 是必填项
                const isAccountRequired = (accountType === 'phone' || accountType === 'email');
                
                // 如果 type 是 email，则验证 account 是否是一个有效的邮箱格式
                const isEmailValid = accountType !== 'email' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(account);
                

                if(isAccountRequired){
                    return transactionPassword && accountType && realName && cpf.length === 11 && account && isEmailValid;
                }else{
                    return transactionPassword && accountType && realName && cpf.length === 11;
                }
            }
        },
        methods:{
            closeModal(){
                this.$emit("update:visible",false);
            },
            confirm(){
                if(this.canPush){
                    if(!this.isPush){
                        this.isPush = true;
                        let data = {
                            ...this.form
                        }
                        AddTranscationInfo(data).then(res => {
                            if(res.code == 200){
                                this.$emit("update:visible",false)
                            }
                            this.$message(res.msg);
                            this.isPush = false;
                        }).catch(err => {
                            this.isPush = false;
                        })
                    }
                }
            },
            typeLabel(type){
                switch(type){
                    case "phone":
                        return 'PIX-TELEFONE';
                    case "email":
                        return 'PIX-EMAIL';
                    case "cpf":
                        return 'PIX-CPF';
                }
            },
            closeModal(){
            },
            toHide(flag){
                this.form.accountType = flag;
                this.select = false;
            }
        },
        watch:{
            password(newVal){
                this.form.transactionPassword = newVal
            }
        }
    }
</script>

<style>
    .formPopover{
        padding:0 !important;
        border:none !important;
        width:35rem;
    }
</style>

<style scoped>

    .formListItem.active{
        color:#fff;
    }
    .formListItem{
        height:6rem;
        width:100%;
        display:flex;
        align-items: center;
        color:var(--disabled14);
        box-sizing:border-box;
        padding-left:2rem;
    }

    .formList{
        background:var(--sub25black);
    }

    .formItem > input::placeholder { 
        font-weight: bold;
        color:var(--disabled14);
    }

    .formItem > input {
        display:flex;
        flex:1;
        width:100%;
        background:none;
        outline:none;
        border:none;
        font-weight:bold;
        box-sizing:border-box;
        padding-left:1rem;
        color: #fff;
    }

    .formItem > svg {
        height:2rem;
        width:3.5rem;
    }

    .formItem{
        height:6rem;
        width:100%;
        display:flex;
        align-items: center;
        border-radius:0.5rem;
        border:1px solid var(--sub25black);
        box-sizing:border-box;
        padding:0 1rem;
        margin-bottom:1.5rem;
    }

    .title svg{
        width:2rem;
        height:2rem;
    }
    .title path{
        fill:var(--link9)
    }
    
    .title > span {
        color:#fff;
        font-size:1.6rem;
        font-weight:bold;
    }

    .title{
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .formButton {
        border-radius: 1rem;
        background: var(--purplebg8);
        height: 5.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        font-size:1.7rem;
        margin-top:2rem;
        cursor:pointer;
    }

    .TranscationModal{
        width:100%;
    }

    .formBox{
        width:100%;
        margin-top:2rem;
    }
</style>