<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <span class="promotionTitle">Promotions</span>
            <PromotionItem 
                v-for="(item,index) in promotionList" 
                :key="index" 
                :img="item.fullNoticeImg" 
                :title="item.noticeTitle" 
                :remark="item.noticeIntroduction"
                :id="item.id"
            >
            </PromotionItem>
        </div>
        <Footer></Footer>
    </div> 
</template>

<script>

    import Footer from "../components/Footer.vue"
    import { getNoticeList } from "@/api/home"
    import PromotionItem from "@/components/PromotionItem.vue";

    export default {
        name:"Promotion",
        data(){
            return {
                promotionList:[
                    {},
                    {},
                    {},
                    {}
                ],
            }
        },
        components:{
            Footer,
            PromotionItem
        },
        created() {
            getNoticeList().then(res=>{
                this.promotionList=res.data.records
            });
        },
        methods:{
            toDetails(){
                this.$router.push("/activity")
            },
        }
    }
</script>

<style scoped>
    .box{
        display:flex;
        flex-direction: column;

        background: var(--subblack);
        min-height:100vh;
        box-sizing:border-box;
        padding-top:8rem;
        padding-bottom:8rem;
        box-sizing:border-box;
        padding:2rem 2rem;
    }
    .promotionTitle{
        font-size:2.5rem;
        color:#fff;
        font-weight:bold;
    }
</style>
