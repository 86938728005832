<template>
    <div class="container" @click="changeModel" :style="'height:'+size+'rem;width:'+size+'rem'">
        <template v-if="model">
            <div class="true">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                  <path d="M442.88 1010.176c-147.968-183.296-322.048-322.048-442.88-365.568l286.72-172.032 138.24 268.8S650.24 195.584 1006.08 13.824C998.4 143.872 963.072 256.512 1024 395.264c-156.16 35.328-476.672 424.96-581.12 614.912z" fill="#ffffff"></path>
                </svg>
            </div>
        </template>
        <template v-else>
            <div class="false">
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name:"Checkbox",
        props:{
            model:{
                type:Boolean,
                default:false,
            },
            size:{
                type:Number,
                default:3
            }
        },
        data(){
            return {

            }
        },
        methods:{
            changeModel(){
                this.$emit("update:model",!this.model)
            }
        }
    }
</script>

<style scoped>
    .true > svg {
        width:50%;
        height:50%;
    }
    .true{
        height:100%;
        width:100%;
        background:var(--greem14);
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .false{
        height:100%;
        width:100%;
        background:var(--sub4black);
    }
    .container{
        border-radius:.5rem;
        overflow:hidden;
    }
</style>