<template>
    <div class="table">
        <div class="tableHeader">
            <div v-for="(item,index) in column" :key="index" class="tableField" :style="'width:' + 100/column.length + '%'" v-text="item.name">
                
            </div>
            <div v-if="control" class="tableField" :style="'width:' + 100/column.length + '%'" v-text="$t('Operation')">
                
            </div>
        </div>
        <div class="tableBody">
            <template v-if="data.length == 0">
                <div style="height:25rem;">
                    <Empty></Empty>
                </div>
            </template>
            <template v-else>
                <div class="tableBodyReal">
                    <div class="tableBodyRow" v-for="(item,index) in data" :key="index">
                        <div class="tableBodyCol" :style="'width:' + 100/column.length + '%'" v-for="(item2) in keys" :key="item2">
                            <svg
                                v-if="item2 == 'id'"
                                :data-clipboard-text="item[item2]"
                                :id="'id'+item[item2]"
                                @click="copyID(item[item2])"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M753.312 698.848 753.312 383.6736c0-62.048-50.3104-112.3264-112.288-112.3264L395.3024 271.3472 324.288 271.3472l-53.7344 0-29.536 0c-62.048 0-112.288 50.272-112.288 112.3264l0 400.0512c0 61.9776 50.24 112.2752 112.288 112.2752l400.0128 0c62.048 0 112.288-50.2976 112.288-112.2752l0-31.0656 0.8768 0 0-54.0352C753.888 698.624 753.6768 698.7776 753.312 698.848zM660.6016 681.728l0 42.5984c0 0.864-0.2368 1.792-0.2368 2.6688-1.4272 42.3552-36.064 76.3008-78.7328 76.3008L300.3392 803.296c-43.5968 0-78.9056-35.3664-78.9056-78.9696L221.4336 442.9888c0-42.2336 33.2608-76.4928 75.0144-78.5984 1.2928-0.064 2.5984-0.3712 3.8912-0.3712l41.4272 0L581.632 364.0192c43.6608 0 78.9696 35.4048 78.9696 78.9696L660.6016 681.728zM895.1232 555.5008 895.1232 240.3264c0-62.048-50.3104-112.3264-112.288-112.3264L537.1136 128 466.0992 128l-53.7344 0-29.536 0c-62.048 0-112.288 50.272-112.288 112.3264l0 4.4736 115.0784 0c13.4912-13.8112 32.0256-22.72 52.6464-23.7568 1.2864-0.064 2.592-0.3712 3.8848-0.3712l41.4272 0 239.8656 0c43.6608 0 78.9696 35.4048 78.9696 78.9696l0 238.7328 0 42.5984c0 0.864-0.2368 1.792-0.2368 2.6688-0.8768 25.9136-14.208 48.6336-34.176 62.4l0 106.6048 14.8352 0c62.048 0 112.288-50.2976 112.288-112.2752l0-31.0656L896 609.3056l0-54.0352C895.6992 555.2768 895.488 555.424 895.1232 555.5008z"
                                    fill="#64d656"
                                >
                                </path>
                            </svg>
                            <div class="tableTextDiv">
                                <span class="tableText" :style="item2 == 'status' ? 'color:'+item['statusc'] : ''" v-text="item[item2]">
                                </span>
                            </div>
                        </div>
                        <div v-if="control" class="tableBodyCol" :style="'width:' + 100/column.length + '%'">
                            <div class="tableTextDiv">
                                <span @click="toDelete(index)" style="font-size:1.2rem;color:red;">{{ $t("Delete") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    import Empty from "./Empty.vue"
    import Clipboard from "clipboard";

    export default {
        name:"Table",
        props:{
            data:{
                type:Array,
                default:[]
            },
            column:{
                type:Array,
                default:[]
            },
            control:{
                type:String,
                default:""
            },
            delete:{
                type:Function,
                default:() => {}
            }
        },
        computed:{
            keys(){
                return Object.values(this.column.map(item => item.key));
            }
        },  
        data(){
            return {
                
            }
        },
        methods:{
            toDelete(index){
                this.delete(this.data[index].id);
            },
            copyID(id) {
                this.$message.info(this.$t("CopySuccess"));
                new Clipboard("#id" + id);
            },
        },
        components:{
            Empty
        }
    }
</script>

<style scoped>
    .tableText{
        color:var(--white5);
        font-size:1.2rem;
        font-weight:bold;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    .tableTextDiv{
        width:fit-content;
        flex:1;
        max-width:80%;
    }
    .tableBodyCol > svg {
        width:1.5rem;
        height:1.5rem;
        margin-right:0.3rem;
        cursor:pointer;
    }
    .tableBodyCol{
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        word-break: break-all;
    }
    .tableLevelRankDes{
        color:#fff;
        font-size:1rem;
        font-weight:bold;
    }
    .tableLevelRankText{
        font-size:1.8rem;
        font-weight:bold;
        color:#fff;
    }
    .tableLevelRank{
        width:5rem;
        height:5rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding-top:.8rem;
    }
    .tableBodyRow{
        height:5rem;
        background:var(--sub29black);
        margin-bottom:.5rem;
        display:flex;
        align-items: center;
        margin-top:.5rem;
        border-radius:.5rem;
    }
    .tableBodyReal{
        display:flex;
        flex-direction: column;
        height:fit-content;
        width:100%;
    }
    .tableBody{
        min-height:25rem;
        height:fit-content;
    }
    .tableField{
        color:var(--disabled6);
        font-size:1.4rem;
        font-weight:bold;
        text-align: center;
        overflow-wrap: break-word;
        box-sizing:border-box;
        padding:0 1rem;
    }
    .tableHeader{
        display:flex;
        height:5rem;
        margin-top:1rem;
        align-items: center;
    }
    .table{
        height:fit-content;
        display:flex;
        flex-direction: column;
    }
</style>