<template>
    <div style="padding:8rem 0">
        <div class="box">
            <div class="helpImg">
                <img :src="Help" />
            </div>
            <div class="helpP">
                <p class="helpTitle">EksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
                <p class="helpDes">EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
            </div>
            <div class="helpP">
                <p class="helpTitle">EksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
                <p class="helpDes">EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
            </div>
            <div class="helpP">
                <p class="helpTitle">EksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
                <p class="helpDes">EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
            </div>
            <div class="helpP">
                <p class="helpTitle">EksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
                <p class="helpDes">EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
            </div>
            <div class="helpP">
                <p class="helpTitle">EksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
                <p class="helpDes">EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
            </div>
            <div class="helpP">
                <p class="helpTitle">EksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
                <p class="helpDes">EksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPeraEksklusibongAffiliateProgram,MasMadalingKumitangPera</p>
            </div>
        </div>
        <div class="optionButton" @click="goBack">
            REFERRAL NOW
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

    import Help from "../assets/img/bg.png";
    import Footer from "../components/Footer.vue"

    export default {
        name:"Help",
        data(){
            return {
                Help
            }
        },
        methods:{
            goBack(){
                this.$router.go(-1);
            },
        },
        components:{
            Footer
        }
    }
</script>

<style scoped>
    .optionButton{
        background-color:var(--red2);
        width:80%;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        height:6rem;
        border-radius:1rem;
        font-size:2rem;
        font-weight:bold;
        margin:0 auto;
        margin-top:2rem;
        margin-bottom:2rem;
    }
    .helpDes{
        font-size:1.5rem;
        color:var(--green10);
        font-weight:bold;
        overflow-wrap: break-word; /* 文字超出容器宽度时换行 */
        word-wrap: break-word; /* 兼容旧版浏览器 */
        margin:0;
        margin-top:.5rem;
    }
    .helpTitle{
        font-size:2rem;
        color:var(--green10);
        font-weight:bold;
        overflow-wrap: break-word; /* 文字超出容器宽度时换行 */
        word-wrap: break-word; /* 兼容旧版浏览器 */
        margin:0;
    }
    .helpP{
        margin-bottom:4rem;
    }
    .helpImg img{
        width:100%;
        height:100%;
    }
    .helpImg{
        width:100%;
        margin-bottom:6rem;
    }
    .box{
        background: var(--subblack);
        box-sizing:border-box;
        height:fit-content;
        min-height:90vh;
        padding-top:8rem;
        padding-bottom:8rem;
        padding:2rem 2rem;
    }
</style>