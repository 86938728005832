import axios from '@/http/index.js'
// import { getToken } from '@/utils/utils.js'

export const getClassList = () => { // 获取分类列表
    return axios.request({
        url: '/game/gameCategory/all',
        params: {},
        headers: {
            isToken: false
        },
        method: 'get'
    })
}

export const getStationImgList = () => {
    return axios.request({
        url: '/station/stationImg/list',
        params: {},
        headers: {
            isToken: false
        },
        method: 'get'
    })
}

export const getNoticeList = () => {
    return axios.request({
        url: '/system/sysNotice/list',
        params: {},
        headers: {
            isToken: false
        },
        method: 'get'
    })
}
export const getNoticeById = (id) => {
    return axios.request({
        url: '/system/sysNotice/detail/'+id,
        params: {},
        headers: {
            isToken: false
        },
        method: 'get'
    })
}

export const getActivityList = () => {
    return axios.request({
        url: '/user/userActivity/current',
        params: {},
        method: 'get'
    })
}