
export function checkAlphanumeric(str) { // 检查是否只有字母和数字
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(str);
}

export function checkEmail(str) { // 检查是否是邮箱
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(str);
}

export function checkPhoneNumber(str) { // 检查是否是10-11位手机号
    const regex = /^\d{10,11}$/;
    return regex.test(str);
}