<template>
    <div class="container">
        <el-date-picker
            type="date"
            v-model="start"
            @change="updateStartDate"
            placeholder="00/00/0000"
            :clearable="false"
            :editable="false"
            prefix-icon="none"
            format="dd/MM/yyyy"
            :append-to-body="false"
        >
        </el-date-picker>
        <div>
            -
        </div>
        <el-date-picker
            type="date"
            v-model="end"
            @change="updateEndDate"
            placeholder="00/00/0000"
            :clearable="false"
            :editable="false"
            prefix-icon="none"
            format="dd/MM/yyyy"
            :append-to-body="false"
        >
        </el-date-picker>
        <div>
            <i class="el-icon-date"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name:"DatePicker",
        props:{
            startDate:{
                default:"0000-00-00"
            },
            endDate:{
                default:"0000-00-00"
            },
        },
        data(){
            return {
                start:new Date(),
                end:new Date(),
            }
        },
        methods:{
            updateStartDate(newValue) {
                this.$emit('update:startDate', newValue);
            },
            updateEndDate(newValue) {
                this.$emit('update:endDate', newValue);
            }
        }
    }
</script>

<style scoped>
    .container>div{
        display:flex;
        height:100%;
        align-items: center;
    }
    .container{
        height:3.5rem;
        display:flex;
        width:fit-content;
        border:1px solid var(--disabled22);
        border-radius:1rem;
        color:var(--disabled2);
        font-size:1.5rem;
        padding-right:1rem;
    }
</style>