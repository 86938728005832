<template>
    <div class="modalShadow" @click="closeModal" :style=" visible ? '' : 'display:none' ">
        <div @click.stop="defaultF" class="modalBox" :style="getStyle()">
            <template v-if="title">
                <div class="modalTitle">
                    <span v-text="title"></span>
                    <svg @click.stop="closeModal" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path></svg>
                </div>
            </template>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name:"Modal",
        props:{
            title:{
                type:String,
            },
            visible:{
                type:Boolean,
                default:false,
            },
            gradient:{
                type:Boolean,
                default:false,
            },
            padding:{
                type:Boolean,
                default:true
            },
            hasBg:{
                type:Boolean,
                default:true
            },
            special:{
                type:Boolean,
                default:false
            },
            beforeClose:{
                type:Function,
                default:()=>{}
            },
            widths:{
                type:Number,
                default:90
            }
        },
        data(){
            return {
                width:0
            }
        },
        mounted(){
            const resize = () => {
                this.$nextTick(() => {
                    if(document.body.style.maxWidth.trim()){
                        this.width = parseInt(document.body.style.maxWidth);
                    }else{
                        this.width = window.innerWidth;
                    }
                })
            }
            resize();

            window.addEventListener('resize', resize);
        },
        methods:{
            defaultF(){

            },
            closeModal(){
                if(this.special){
                    this.$children[0].closeModal();
                    return ;
                }
                this.beforeClose();
                this.$emit("update:visible",false)
            },
            getStyle(){
                let result = "";

                if(this.gradient){
                    result += 'background: linear-gradient(to bottom, #0b2b62, var(--sub8black));'
                }else if (!this.hasBg){
                    result += 'background:none;';
                }
                if(!this.padding){
                    result += 'padding:0 !important;'
                }
                result += "max-width:" + this.width + 'px;width:' + this.widths + '%'

                return result;
            }
        }
    }
</script>

<style scoped>
    .modalTitle svg{
        width:2rem;
        height:2rem;
    }
    .modalTitle path{
        fill:var(--link9)
    }
    .modalTitle{
        display:flex;
        justify-content: space-between;
        color:#fff;
        font-size:2rem;
        font-weight:bold;
    }
    .modalShadow{
        height:100%;
        width:100vw;
        position:fixed;
        z-index:999999;
        background-color:rgba(0,0,0,.8);
        display:flex;
        justify-content:center;
        align-items: center;
        top:0;
        left:0;
    }
    .modalBox{
        max-width:557px;
        background:var(--primary);
        box-sizing:border-box;
        padding:3rem 3rem;
        border-radius:1rem;
        height:fit-content;
        width:90%;
    }
</style>
