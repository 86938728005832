<template>
    <div class="box">
        <img :src="logorow" />
        <div class="regionItem">
            <img :src="ban" />
            <div class="regionRight">
                <span>This game is not available in your country.</span>
                <span>Due to licensing regulation laws.we cannot offer our services to you.</span>
            </div>
        </div>
        <div class="regionItem">
            <img :src="world" />
            <div class="regionRight">
                <span>Not in a restricted region?</span>
                <span>If you're using aproxy service or VPN to access.try turning it off and reload the page.</span>
            </div>
        </div>
        <div class="regionButton">
            Contact Support
        </div>
        <div class="service">
            <img :src="service2" />
            <span>Service</span>
        </div>
    </div>
</template>

<script>

    import logorow from "@/assets/img/logorow.png";
    import ban from "@/assets/img/ban.png";
    import world from "@/assets/img/world.png";
    import service2 from "@/assets/img/service2.png";

    export default {
        name:"Region",
        data(){
            return {
                logorow,
                ban,
                world,
                service2
            }
        },
        methods:{

        },

    }
</script>

<style scoped>
    .service>span{
        color:var(--yellow);
        margin-top:.1rem;
        font-size:1.3rem;
    }
    .service>img{
        width:4rem;
    }
    .service{
        position:fixed;
        top:2rem;
        right:2rem;
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .regionButton{
        width:70%;
        background:var(--red);
        height:5rem;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.6rem;
        font-weight:bold;
        border-radius:.5rem;
        margin-top:4rem;
    }

    .regionItem{
        display:flex;
        justify-content: space-between;
        line-height:2.3rem;
        margin-bottom:2rem;
    }
    .regionItem>img{
        width:5rem;
        height:fit-content;
        /* height:5rem; */
    }
    .regionRight>span:nth-child(1){
        color:#fff;
        font-weight:bold;
        font-size:1.5rem;
    }
    .regionRight>span:nth-child(2){
        color:var(--disabled5);
        font-weight:bold;
        font-size:1.4rem;
        margin-top:1rem;
    }
    .regionRight{
        display:flex;
        width:100%;
        flex:1;
        flex-direction: column;
        margin-left:3rem;
        word-break: break-all;
    }
    .box>img{
        width:40%;
        margin-bottom:4rem;
    }
    .box{
        box-sizing:border-box;
        padding:0 4rem;
        width:100%;
        height:100vh;
        background:var(--subblack);
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>