<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="fadeBox" id="fadeBox" v-if="messageList!=null">
                <template v-if="!$store.state.user.token">
                    <Empty mode="light"></Empty>
                </template>
                <template>
                    <div class="inboxList">
                        <InboxItem 
                        v-for="(item, index) in messageList"
                        :item="item"
                        :key="index"
                        :loading="false"
                        @toOpen="toOpen">
                        </InboxItem>
                        <template v-if="loading">
                            <InboxItem 
                                v-for="(item, index) in loadList"
                                :item="item"
                                :key="index"
                                @toOpen="toOpen"
                                :loading="true"
                            >
                            </InboxItem>
                        </template>
                        <div class="loadMore" @click="loadMore" v-if="messageList.length < pageInfo.total">
                            {{ $t("LoadMore") }}
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import Empty from "../components/Empty.vue";
import InboxItem from "../components/InboxItem.vue";

import {getUserMessageApi, readUserMessageApi, readUserMessageByIdApi} from "../api/user"
import { mapState } from 'vuex';

export default {
    name: "Inbox",
    data() {
        return {
            opens: [],
            show: false,
            messageList: [],
            loadList:[{},{},{},{},{},{},{}],
            pageInfo:{
                page:1,
                total:0,
                size:100
            },
            loading:true
        };
    },
    components: {
        Empty,
        InboxItem
    },
    computed:{
        ...mapState({
            token: state => state.user.token,
        })
    },
    watch:{
        token(){
            this.messageList = [];
            this.pageInfo.page = 1;
            this.getMessage();
        }
    },
    methods: {
        toOpen(item){
            // readMessage
            item.open = !item.open;
            if(item.messageRead == 0 && item.open){ // 未读
                readUserMessageApi({
                    id:item.id
                }).then(res => {
                    if(res.code == 200){
                        item.messageRead = 1;
                        this.$store.dispatch("readMessage");
                    }
                })
            }
        },
        loadMore(){
            this.loading = true;
            this.getMessage();
        },
        getMessage() {
            let data = {
                pageSize:this.pageInfo.size,
                currentPage:this.pageInfo.page
            }
            getUserMessageApi(data).then(res => {
                res.data.records.map(item => {
                    item.open = false;
                    return item;
                })
                this.messageList = [...this.messageList,...res.data.records];
                this.pageInfo.total = res.data.total;
                this.pageInfo.page += 1;
                this.loading = false;
            })
        }
    },
    created() {
        this.getMessage();
    },
};
</script>

<style scoped>

.loadMore{
    box-sizing:border-box;
    padding:1rem 1.5rem;
    border-radius:1rem;
    background:var(--sub20black);
    color:#58677c;
    width:fit-content;
    margin:0 auto;
    margin-top:1.5rem;
    font-size:1.5rem;
    font-weight:bold;
    cursor:pointer;
}

.fade-enter-active {
    animation: fadeIn 0.3s;
}

.fade-leave-active {
    /* animation: fadeIn 0.1s reverse; */
}

@keyframes fadeIn {
    0% {
        /* margin-left: 0vw; */
        left: 100vw;
    }
    100% {
        /* margin-left: 100vw; */
        left: 0;
    }
}

.viewBoxGlobal {
    height: 100%;
    padding: 0;
}

.inboxList {
    /* display: flex; */
    flex-direction: column;
    box-sizing: border-box;
    padding: 2rem 3rem;
    font-weight: bold;
    overflow-y: scroll;
    scrollbar-width: none;
    height: 100%;
    padding-bottom: 10rem;

}

.inboxList::-webkit-scrollbar {
    display: none;
}

.inboxListItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.3rem;
}

.inboxListItem > span {
    color: var(--disabled2);
    font-size: 1.5rem;
}

.inboxList::-webkit-scrollbar {
    display: none;
}

.receiveButton {
    background: var(--noticebg);
    border-radius: 1rem;
    color: #fff;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 15rem;
    font-weight: bold;
}

.deleteButton {
    background: var(--green9);
    border-radius: 1rem;
    color: #fff;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 15rem;
    margin-right: 2rem;
    font-weight: bold;
}

.optionBox {
    position: absolute;
    z-index: 99;
    left: 0;
    bottom: 0;
    background: var(--sub21black);
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    height: fit-content;
    height: calc(100% - 8rem);
    width: 100%;
    background: var(--primary);
    position: relative;
    box-sizing: border-box;
    padding-top: 8rem;
}

.fadeBox {
    position: relative;
    height: 100%;
}
</style>
