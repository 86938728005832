<template>
    <div>
        <slot></slot>
        <div class="fixed-nav" v-show="scrollTop">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name:"ClassList",
        data(){
            return {
            }
        },
        props:{
            scrollTop:{
                type:Boolean
            }
        },
        methods:{
        },
        mounted(){
        },
    }

</script>

<style>
    .fixed-nav .class-item-box{
        background: var(--sub4black);
    }
</style>

<style scoped>
    .fixed-nav{
        position:absolute;
        top:6rem;
        left:0;
        z-index:30;
        box-sizing:border-box;
        padding:0 2rem;
        width:100%;
        overflow:hidden;
        background: var(--primary);
    }
</style>