<template>
    <div class="table">
        <div class="tableHeader">
            <div v-for="(item,index) in column" :key="index" class="tableField" :style="'width:' + 100/column.length + '%'" v-text="item.name">
                
            </div>
        </div>
        <div class="tableBody">
            <template v-if="data.length == 0">
                <div style="height:25rem;">
                    <Empty></Empty>
                </div>
            </template>
            <template v-else>
                <div class="tableBodyReal">
                    <div class="tableBodyRow" v-for="(item,index) in data" :key="index">
                        <div class="tableBodyCol" style="width:16.66%">
                            <div class="tableLevelRank" :style="'background-image:url(' + RankBg + ')'">
                                <span class="tableLevelRankText">0</span>
                                <span class="tableLevelRankDes">VIP</span>
                            </div>
                        </div>
                        <div class="tableBodyCol" style="width:16.66%">
                            <span class="tableText">
                                0.40%
                            </span>
                        </div>
                        <div class="tableBodyCol" style="width:16.66%">
                            <span class="tableText">
                                0.50%
                            </span>
                        </div>
                        <div class="tableBodyCol" style="width:16.66%">
                            <span class="tableText">
                                0.40%
                            </span>
                        </div>
                        <div class="tableBodyCol" style="width:16.66%">
                            <span class="tableText">
                                0.40%
                            </span>
                        </div>
                        <div class="tableBodyCol" style="width:16.66%">
                            <span class="tableText">
                                0.40%
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    import Empty from "./Empty.vue"

    export default {
        name:"Table",
        props:{
            data:{
                type:Array,
                default:[]
            },
            column:{
                type:Array,
                default:[]
            }
        },
        data(){
            return {

            }
        },
        methods:{

        },
        components:{
            Empty
        }
    }
</script>

<style scoped>
    .tableText{
        color:var(--white5);
        font-size:1.5rem;
    }
    .tableBodyCol{
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .tableLevelRankDes{
        color:#fff;
        font-size:1rem;
        font-weight:bold;
    }
    .tableLevelRankText{
        font-size:1.8rem;
        font-weight:bold;
        color:#fff;
    }
    .tableLevelRank{
        width:5rem;
        height:5rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding-top:.8rem;
    }
    .tableBodyRow{
        height:8rem;
        background:var(--sub29black);
        margin-bottom:.5rem;
        display:flex;
        align-items: center;
    }
    .tableBodyReal{
        display:flex;
        flex-direction: column;
        height:fit-content;
        width:100%;
    }
    .tableBody{
        min-height:25rem;
        height:fit-content;
    }
    .tableField{
        color:var(--disabled6);
        font-size:1.4rem;
        font-weight:bold;
        text-align: center;
        overflow-wrap: break-word;
        box-sizing:border-box;
        padding:0 1rem;
    }
    .tableHeader{
        display:flex;
        height:5rem;
        margin-top:1rem;
        align-items: center;
    }
    .table{
        height:fit-content;
        display:flex;
        flex-direction: column;
    }
</style>