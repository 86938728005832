<template>
    <div class="container">
        <div class="arrowItem" :style="'margin-bottom:'+margin+'rem'" v-for="item in number" :key="item">
            <div class="arrow"></div>
            <div class="arrow"></div>
            <div class="arrow"></div>
        </div>
    </div>
</template>


<script>
    export default {
        name:"Transition",
        props:{
            number:{
                type:Number,
                default:5
            },
            margin:{
                type:Number,
                default:4.8
            }
        },
        data(){
            return {

            }
        },
        methods:{

        }
    }
</script>

<style scoped>
    .container {
        width: fit-content; /* 箭头宽度加上间距 */
        height: fit-content;
        position: relative;
        box-sizing:border-box;
        padding-top:5rem;
        display:flex;
        flex-direction: column;
    }
    .arrowItem{
        position:relative;
        height:fit-content;
        width:fit-content;
    }
    .arrowItem:last-child{
        margin-bottom:0 !important;
    }
    .arrow {
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
        border-right: 1rem solid var(--disabled26); /* 箭头颜色 */
        transform: rotate(180deg);
        position: absolute;
        top: 50%;
        left: 3rem;
        opacity: 0; /* 初始设置透明度为0 */
        animation: fadeInOut 3s infinite; /* 指示箭头动画 */
    }

  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }

  .arrow:nth-child(2) {
    left: 4.5rem; /* 第二个箭头向右偏移60px */
    animation-delay: 1s; /* 第二个箭头延迟1s开始动画 */
  }

  .arrow:nth-child(3) {
    left: 6rem; /* 第三个箭头向右偏移120px */
    animation-delay: 2s; /* 第三个箭头延迟2s开始动画 */
  }
</style>