<template>
    <div class="viewBoxGlobal" style="background:var(--subblack)">
        <div class="box">
            <div class="classList">
                <div @click="changeClass(index)" :class="item.active ? 'classItem active buttonTransition' : 'classItem buttonTransition'" v-for="(item,index) in classList" :key="index">
                    <span v-text="item.text"></span>
                </div>
            </div>
            <div class="searchBox">
                <DatePicker></DatePicker>
                <div class="searchButton buttonTransition">Search</div>
            </div>
            <TableSimple :column="column" :data="data"></TableSimple>
        </div>
    </div>
</template>

<script>

    import DatePicker from "@/components/DatePicker.vue";
    import TableSimple from "@/components/TableSimple.vue";

    export default {
        name:"Report",
        components:{
            DatePicker,
            TableSimple
        },
        data(){
            return {
                classList:[
                    { text:"Recordes de apostas",active:true },
                    { text:"Bonus",active:false },
                    { text:"Rebate",active:false },
                    { text:"Ajuste",active:false },
                    { text:"Comissao",active:false },
                    { text:"Auditoria",active:false }
                ],
                column:[
                    { name:"ID transcation",key:'id' },
                    { name:"Encontro",key:'enctype' },
                    { name:"Valor do deposit",key:'deposit' },
                    { name:"Bonus",key:'bonus' },
                    { name:"Status",key:'status' },
                ],
                data:[
                    {
                        id:1,
                        enctype:"2024-09-07 07:16:31",
                        deposit:20,
                        bonus:0,
                        status:0
                    },
                    {
                        id:2,
                        enctype:"2024-09-07 07:16:31",
                        deposit:20,
                        bonus:0,
                        status:0
                    },
                    {
                        id:2,
                        enctype:"2024-09-07 07:16:31",
                        deposit:20,
                        bonus:0,
                        status:0
                    }
                ]
            }
        },
        methods:{
            changeClass(index){
                this.classList.forEach(item => {
                    item.active = false;
                })
                this.classList[index].active = true;
            }
        }
    }
</script>

<style scoped>

    .searchButton {
        background: var(--blue);
        color: #fff;
        border-radius: 1rem;
        box-sizing: border-box;
        padding: 0 2em;
        height: 3.5rem;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        margin-left: 1rem;
    }

    .searchBox{
        display:flex;
        align-items: center;
        margin-bottom:1rem;
        margin-top:1rem;
    }

    .classItem.active{
        background:var(--purplebg10);
    }
    .classItem:nth-child(3n){
        margin-right:0 !important;
    }
    .classItem{
        width:calc((100% - 2rem) / 3);
        height:4rem;
        background:var(--primary);
        display:flex;
        align-items: center;
        color:#fff;
        font-weight:bold;
        font-size:1.2rem;
        margin-right:1rem;
        margin-bottom:1rem;
        border-radius:0.5rem;
        white-space: nowrap;
        justify-content: center;
        cursor:pointer;
    }
    .classList{
        display:flex;
        flex-wrap:wrap;
        align-items: center;
        justify-content: center;
        height:fit-content;
        width:100%;
    }
    .box{
        display:flex;
        flex-direction: column;
        height:100%;
        width:100%;
        height:fit-content;
        box-sizing: border-box;
        padding:2rem 2rem;
    }
</style>