<template>
    <div class="promotionItem">
        <div class="promotion-item" v-show="!loading" @click="toActivity">
            <img :src="img" @load="load" />
            <div class="promotion-right">
                <span v-text="title"></span>
                <span v-text="remark"></span>
                <span style="cursor:pointer;">{{ $t("ClickForMore") }}>></span>
            </div>
        </div>
        <div class="skeleton" v-show="loading">
        </div>
    </div>
</template>

<script>
    export default {
        name:"PromotionItem",
        props:{
            img:{
                type:String
            },
            title:{
                type:String
            },
            remark:{
                type:String
            },
            id:{
                type:Number
            }
        },
        data(){
            return {
                loading:true,
            }
        },
        methods:{
            toActivity(){
                this.$router.push({
                    query:{
                        id:this.id
                    },
                    path:"/activity"
                })
            },
            load(){
                this.loading = false;
            }
        }
    }
</script>

<style scoped>
    .promotionItem{
        overflow:hidden;
        width:100%;
        border-radius:1rem;
        height:15rem;
        margin-top:2rem;
    }
    .promotion-right span:nth-child(1){
        color:var(--phonetext);
        font-size:1.5rem;
        font-weight:bold;
    }
    .promotion-right span:nth-child(2){
        color:#ffffff;
        font-size:1.5rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .promotion-right span:nth-child(3){
        color:var(--disabled6);
        font-size:1.5rem;
        margin-top:auto;
    }
    .promotion-right{
        display:flex;
        flex-direction: column;
        margin-left:1rem;
    }
    .promotion-item img {
        height:12rem;
    }
    .promotion-item{
        height:15rem;
        display:flex;
        background:var(--primary);
        box-sizing: border-box;
        padding:1.5rem;
    }
</style>